import React from "react";
import { Signup } from "../LoginAndSignup/Signup";
import { ConnectTextile } from "../HomeComponents/ConnectTextile";

export const SignupMain = () => {
  return (
    <>
      <Signup />
      <ConnectTextile />
    </>
  );
};
