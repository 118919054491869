import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";

export const CompanyKeywords = () => {
  return (
    <div className="mt-3 bg-white rounded-lg py-2">
      <div className="p-3">
        <h2 className="text-2xl" style={{ color: "#414042" }}>
          Company Keywords
        </h2>
      </div>
      <hr />
      <div className="p-3">
        <Container>
          <Row xs={2} md={4} lg={8}>
            <Col>
              <div className="p-3">
                <ul className="ddes">
                  <li>
                    <div
                      style={{
                        border: "1px solid #3bb44a",
                        textAlign: "center",
                        borderRadius: "20px",
                      }}
                    >
                      <h2>Foosd Product</h2>
                    </div>
                  </li>
                  <li>
                    <div
                      style={{
                        border: "1px solid #3bb44a",
                        textAlign: "center",
                        borderRadius: "20px",
                      }}
                    >
                      <h2>Foosd Product</h2>
                    </div>
                  </li>
                  <li>
                    <div
                      style={{
                        border: "1px solid #3bb44a",
                        textAlign: "center",
                        borderRadius: "20px",
                      }}
                    >
                      <h2>Foosd Product</h2>
                    </div>
                  </li>
                  <li>
                    <div
                      style={{
                        border: "1px solid #3bb44a",
                        textAlign: "center",
                        borderRadius: "20px",
                      }}
                    >
                      <h2>Foosd Product</h2>
                    </div>
                  </li>
                  <li>
                    <div
                      style={{
                        border: "1px solid #3bb44a",
                        textAlign: "center",
                        borderRadius: "20px",
                      }}
                    >
                      <h2>Foosd Product</h2>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
            <Col>
              <div className="p-3">
                <ul className="ddes">
                  <li>
                    <div
                      style={{
                        border: "1px solid #3bb44a",
                        textAlign: "center",
                        borderRadius: "20px",
                      }}
                    >
                      <h2>Foosd Product</h2>
                    </div>
                  </li>
                  <li>
                    <div
                      style={{
                        border: "1px solid #3bb44a",
                        textAlign: "center",
                        borderRadius: "20px",
                      }}
                    >
                      <h2>Foosd Product</h2>
                    </div>
                  </li>
                  <li>
                    <div
                      style={{
                        border: "1px solid #3bb44a",
                        textAlign: "center",
                        borderRadius: "20px",
                      }}
                    >
                      <h2>Foosd Product</h2>
                    </div>
                  </li>
                  <li>
                    <div
                      style={{
                        border: "1px solid #3bb44a",
                        textAlign: "center",
                        borderRadius: "20px",
                      }}
                    >
                      <h2>Foosd Product</h2>
                    </div>
                  </li>
                  <li>
                    <div
                      style={{
                        border: "1px solid #3bb44a",
                        textAlign: "center",
                        borderRadius: "20px",
                      }}
                    >
                      <h2>Foosd Product</h2>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
            <Col>
              <div className="p-3">
                <ul className="ddes">
                  <li>
                    <div
                      style={{
                        border: "1px solid #3bb44a",
                        textAlign: "center",
                        borderRadius: "20px",
                      }}
                    >
                      <h2>Foosd Product</h2>
                    </div>
                  </li>
                  <li>
                    <div
                      style={{
                        border: "1px solid #3bb44a",
                        textAlign: "center",
                        borderRadius: "20px",
                      }}
                    >
                      <h2>Foosd Product</h2>
                    </div>
                  </li>
                  <li>
                    <div
                      style={{
                        border: "1px solid #3bb44a",
                        textAlign: "center",
                        borderRadius: "20px",
                      }}
                    >
                      <h2>Foosd Product</h2>
                    </div>
                  </li>
                  <li>
                    <div
                      style={{
                        border: "1px solid #3bb44a",
                        textAlign: "center",
                        borderRadius: "20px",
                      }}
                    >
                      <h2>Foosd Product</h2>
                    </div>
                  </li>
                  <li>
                    <div
                      style={{
                        border: "1px solid #3bb44a",
                        textAlign: "center",
                        borderRadius: "20px",
                      }}
                    >
                      <h2>Foosd Product</h2>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
            <Col>
              <div className="p-3">
                <ul className="ddes">
                  <li>
                    <div
                      style={{
                        border: "1px solid #3bb44a",
                        textAlign: "center",
                        borderRadius: "20px",
                      }}
                    >
                      <h2>Foosd Product</h2>
                    </div>
                  </li>
                  <li>
                    <div
                      style={{
                        border: "1px solid #3bb44a",
                        textAlign: "center",
                        borderRadius: "20px",
                      }}
                    >
                      <h2>Foosd Product</h2>
                    </div>
                  </li>
                  <li>
                    <div
                      style={{
                        border: "1px solid #3bb44a",
                        textAlign: "center",
                        borderRadius: "20px",
                      }}
                    >
                      <h2>Foosd Product</h2>
                    </div>
                  </li>
                  <li>
                    <div
                      style={{
                        border: "1px solid #3bb44a",
                        textAlign: "center",
                        borderRadius: "20px",
                      }}
                    >
                      <h2>Foosd Product</h2>
                    </div>
                  </li>
                  <li>
                    <div
                      style={{
                        border: "1px solid #3bb44a",
                        textAlign: "center",
                        borderRadius: "20px",
                      }}
                    >
                      <h2>Foosd Product</h2>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
