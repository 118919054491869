import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

export const Faq = () => {
  return (
    <>
      <Container fluid className=" py-4" style={{ backgroundColor: "white" }}>
        <Container>
          <Row xs={2} md={4} lg={6} className="align-items-center webkit ghgh">
            <Col>
              <div className="p-4">
                <Image src={require("../Assets/Partner1.png")} />
              </div>
            </Col>
            <Col>
              <div className="p-4">
                <Image src={require("../Assets/Partner2.png")} />
              </div>
            </Col>

            <Col>
              <div className="p-4">
                <Image src={require("../Assets/Partner3.png")} />
              </div>
            </Col>
            <Col>
              <div className="p-4">
                <Image src={require("../Assets/Partner4.png")} />
              </div>
            </Col>

            <Col>
              <div className="p-4">
                <Image src={require("../Assets/Partner5.png")} />
              </div>
            </Col>
            <Col>
              <div className="p-4">
                <Image src={require("../Assets/Partner6.png")} />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        <Row className="py-3 webkit">
          <div className="py-5">
            <h2 className="text-2xl font-semibold" style={{ color: "#1E75BB" }}>
              Frequently Asked Questions
            </h2>
            <p className="text-lg" style={{ color: "#414042" }}>
              Find answers from the Exporters Team
            </p>
          </div>
        </Row>
      </Container>
      <Container fluid className="bg-white">
        <Container className="py-2">
          <Row className="align-items-center">
            <Col lg="7">
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Why selling on the Exporters marketplace and not through an
                    online shop ?
                  </Accordion.Header>
                  <Accordion.Body>
                    On a marketplace such as Exporters you can show your
                    business and product portfolio to a wide range of new buyers
                    without the need of developing an own online shop or
                    expanding your marketing efforts for an existing online
                    shop.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    Is Exporters free of charge ?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    We are not looking to export at this stage. Can we still use
                    Exporters ?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    We are already selling online. What can you do for us ?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    How does Exporters ensure data security? Will prices be
                    displayed ?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    Can I register as both: Buyer and Supplier?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col lg="5">
              <div className="p-3 webkit">
                <Image className="w-9/12" src={require("../Assets/faq.png")} />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
