import React, { useState, Component, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "../NavbarComponent/Navbar.css";
import "../Assets/logo.png";
import { Image, NavItem } from "react-bootstrap";
import {
  BrowserRouter as Route,
  Router,
  Link,
  Switch,
  useLocation,
} from "react-router-dom";

export const Topnav = () => {
  const [isNavLink, setIsNavLink] = useState("Categories");

  return (
    <Navbar bg="light" id="botone" expand="lg">
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <Image src={require("../Assets/logo.png")} />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="ml-auto my-2" navbarScroll>
            <Link to="/Products" className="nav-link">
              Products
            </Link>
            <Link to="/Exporters" className="nav-link">
              Exporters
            </Link>
            <Link to="/MainCategory" className="nav-link">
              Categories
            </Link>
            {/* <NavDropdown title={isNavLink} id="navbarScrollingDropdown">
              <Link
                className="dropdown-item"
                onClick={() => setIsNavLink("Categories")}
              >
                Categories
              </Link>

              <Link
                className="dropdown-item"
                onClick={() => setIsNavLink("Help & Support")}
              >
                Help & Support
              </Link>

              <Link
                className="dropdown-item"
                onClick={() => setIsNavLink("How it works")}
              >
                How it works
              </Link>

              <Link
                className="dropdown-item"
                onClick={() => setIsNavLink("Our Story")}
              >
                Our Story
              </Link>

              <Link
                className="dropdown-item"
                onClick={() => setIsNavLink("Why Me2Works")}
              >
                Why Me2Works
              </Link>

              <Link
                className="dropdown-item"
                onClick={() => setIsNavLink("Membership Plans")}
              >
                Membership Plans
              </Link>

              <Link
                className="dropdown-item"
                onClick={() => setIsNavLink("Extend Knowledge")}
              >
                Extend Knowledge
              </Link>
            </NavDropdown> */}
            <Link to="/BuyNewRecuirements" className="nav-link">
              Add Buy Offer
            </Link>
            <Link to="/Login" className="nav-link">
              <Button className="btn-login w-full">Login</Button>
            </Link>

            <Link to="/Signup" className="nav-link">
              <Button className="btn-signup w-full">Sign up</Button>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
