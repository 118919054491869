import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";

export const ThreeCols = () => {
  return (
    <div className="mt-3">
      <Row>
        <Col lg="4">
          <div className="p-3 bg-white rounded-lg py-3 mt-2">
            <h2 className="text-2xl" style={{ color: "#414042" }}>
              Trade Information
            </h2>
            <hr />
            <ul className="list-disc ddess">
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <li>Business Nature</li>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className="list-disc">
                      <li>Business Nature</li>

                      <li>Production Capacity</li>
                      <li>Export Countries/ Regions</li>
                      <li> Import Countries/Regions</li>
                      <li>No, of Transactions</li>
                      <li>Logistics/shipment Info</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <li>Production Capacity</li>
                <div
                  style={{
                    marginLeft: "20px",
                    marginTop: "15px",
                    color: "#1E75BB",
                  }}
                >
                  (100MT)
                </div>
              </div>
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <li>Export Countries/ Regions</li>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className="list-disc">
                      <li>Business Nature</li>

                      <li>Production Capacity</li>
                      <li>Export Countries/ Regions</li>
                      <li> Import Countries/Regions</li>
                      <li>No, of Transactions</li>
                      <li>Logistics/shipment Info</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <li>Import Countries/Regions</li>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className="list-disc">
                      <li>Business Nature</li>

                      <li>Production Capacity</li>
                      <li>Export Countries/ Regions</li>
                      <li> Import Countries/Regions</li>
                      <li>No, of Transactions</li>
                      <li>Logistics/shipment Info</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <li>No, of Transactions</li>
                <div
                  style={{
                    marginLeft: "20px",
                    marginTop: "15px",
                    color: "#1E75BB",
                  }}
                >
                  (10)
                </div>
              </div>
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <li>Logistics/shipment Info</li>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s,
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </ul>
          </div>
        </Col>
        <Col lg="4">
          <div className="p-3 bg-white rounded-lg py-3 mt-2 h-96">
            <h2 className="text-2xl" style={{ color: "#414042" }}>
              Finanical Information
            </h2>
            <hr />
            <ul className="list-disc ddess">
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <li>Name of Banks</li>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className="list-disc">
                      <li>Meezan Bank</li>

                      <li>Habib Bank</li>
                      <li>ubl</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <li>Type of Currencies</li>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className="list-disc">
                      <li>Meezan Bank</li>

                      <li>Habib Bank</li>
                      <li>ubl</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <li>Annual Turnover</li>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className="list-disc">
                      <li>Meezan Bank</li>

                      <li>Habib Bank</li>
                      <li>ubl</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </ul>
          </div>
        </Col>
        <Col lg="4">
          <div className="p-3 bg-white rounded-lg py-3 mt-2 h-96">
            <h2 className="text-2xl" style={{ color: "#414042" }}>
              Certification & QC
            </h2>
            <hr />
            <ul className="list-disc ddess">
              <li>Trademark Certificates</li>
              <li>Company ISO Certifications</li>
              <li>Industrial/Product Certifications</li>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
};
