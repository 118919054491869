import React from "react";
import { Col, Container, Row, Image, Button } from "react-bootstrap";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { BsStarFill, BsStarHalf } from "react-icons/bs";

import { Link } from "react-router-dom";
import { ColThreeCat } from "./ColThreeCat";

export const ProductCategory = () => {
  return (
    <Container>
      {/* <div className="py-3 webkit">
        <h2 className="text-2xl font-bold" style={{ color: "#1E75BB" }}>
          Exporters
        </h2>
      </div> */}
      <Row>
        <Col lg="9">
          <Row lg={12} className="align-items-center webkit">
            <Col lg="6">
              <div className="p-2">
                <div
                  className=" rounded-lg text-left px-2 py-2"
                  style={{ background: "#FFF8BA" }}
                >
                  <div className="p-2 mmd-box">
                    <span className="font-bold float-right text-lg">$2</span>
                    <h3 className=" font-bold">Extra Virgin Olive Oil</h3>
                    <h4 style={{ color: "#6D6E71" }}>500 no. (min MOQ)</h4>
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col lg="9">
                          <Image src={require("../Assets/bottlefull.png")} />
                        </Col>
                        <Col lg="3" style={{ textAlign: "-webkit-left" }}>
                          <div className="exportC">
                            <Image src={require("../Assets/1stbot.png")} />
                            <Image src={require("../Assets/2ndbot.png")} />
                            <Image src={require("../Assets/3rdbot.png")} />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="py-1 align-items-center d-flex">
                      <div className="inline mt-0">
                        <Col lg="9">
                          <div className="flex align-items-center gap-1">
                            <Image
                              style={{ objectFit: "contain" }}
                              src={require("../Assets/pakflag.png")}
                            />
                            <h2
                              style={{
                                color: "black",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              AaFood
                            </h2>
                          </div>
                        </Col>
                        <Col lg="3">
                          <div style={{ textAlign: "-webkit-right" }}>
                            <div
                              className="text-center flex"
                              style={{ color: "#FFBC00" }}
                            >
                              <BsStarFill style={{ fontSize: "15" }} />
                              <BsStarFill style={{ fontSize: "15" }} />
                              <BsStarFill style={{ fontSize: "15" }} />
                              <BsStarFill style={{ fontSize: "15" }} />
                              <BsStarHalf style={{ fontSize: "15" }} />
                            </div>
                            {/* <Bs0Circle */}
                          </div>
                        </Col>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div className="p-2">
                <div
                  className=" rounded-lg text-left px-2 py-2"
                  style={{ background: "#FFF8BA" }}
                >
                  <div className="p-2 mmd-box">
                    <span className="font-bold float-right text-lg">$2</span>
                    <h3 className=" font-bold">Extra Virgin Olive Oil</h3>
                    <h4 style={{ color: "#6D6E71" }}>500 no. (min MOQ)</h4>
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col lg="9">
                          <Image src={require("../Assets/bottlefull.png")} />
                        </Col>
                        <Col lg="3" style={{ textAlign: "-webkit-left" }}>
                          <div className="exportC">
                            <Image src={require("../Assets/1stbot.png")} />
                            <Image src={require("../Assets/2ndbot.png")} />
                            <Image src={require("../Assets/3rdbot.png")} />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="py-1 align-items-center d-flex">
                      <div className="inline mt-0">
                        <Col lg="9">
                          <div className="flex align-items-center gap-1">
                            <Image
                              style={{ objectFit: "contain" }}
                              src={require("../Assets/pakflag.png")}
                            />
                            <h2
                              style={{
                                color: "black",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              AaFood
                            </h2>
                          </div>
                        </Col>
                        <Col lg="3">
                          <div style={{ textAlign: "-webkit-right" }}>
                            <div
                              className="text-center flex"
                              style={{ color: "#FFBC00" }}
                            >
                              <BsStarFill style={{ fontSize: "15" }} />
                              <BsStarFill style={{ fontSize: "15" }} />
                              <BsStarFill style={{ fontSize: "15" }} />
                              <BsStarFill style={{ fontSize: "15" }} />
                              <BsStarHalf style={{ fontSize: "15" }} />
                            </div>
                            {/* <Bs0Circle */}
                          </div>
                        </Col>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center webkit">
            <Col lg="4">
              <div className="p-2 mmd-box">
                <div className=" bg-white rounded-sm text-left px-2 py-2">
                  <h3 className=" font-bold">Frozen Green Peas & Carrots</h3>
                  <h4 style={{ color: "#6D6E71" }}>500 no. (min MOQ)</h4>
                  <Image src={require("../Assets/mutton.png")} />
                  <div className="py-1 align-items-center d-flex">
                    <div className="inline mt-0">
                      <Col lg="9">
                        <div className="flex align-items-center gap-1">
                          <Image
                            style={{ objectFit: "contain" }}
                            src={require("../Assets/pakflag.png")}
                          />
                          <h2
                            style={{
                              color: "black",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            AaFood
                          </h2>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="float-right">
                          <h2 className="text-sm font-bold">$1</h2>
                        </div>
                      </Col>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="4">
              <div className="p-2 mmd-box">
                <div className=" bg-white rounded-sm text-left px-2 py-2">
                  <h3 className=" font-bold">Frozen Green Peas & Carrots</h3>
                  <h4 style={{ color: "#6D6E71" }}>500 no. (min MOQ)</h4>
                  <Image src={require("../Assets/mutton.png")} />
                  <div className="py-1 align-items-center d-flex">
                    <div className="inline mt-0">
                      <Col lg="9">
                        <div className="flex align-items-center gap-1">
                          <Image
                            style={{ objectFit: "contain" }}
                            src={require("../Assets/pakflag.png")}
                          />
                          <h2
                            style={{
                              color: "black",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            AaFood
                          </h2>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="float-right">
                          <h2 className="text-sm font-bold">$1</h2>
                        </div>
                      </Col>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg="4">
              <div className="p-2 mmd-box">
                <div className=" bg-white rounded-sm text-left px-2 py-2">
                  <h3 className=" font-bold">Frozen Green Peas & Carrots</h3>
                  <h4 style={{ color: "#6D6E71" }}>500 no. (min MOQ)</h4>
                  <Image src={require("../Assets/mutton.png")} />
                  <div className="py-1 align-items-center d-flex">
                    <div className="inline mt-0">
                      <Col lg="9">
                        <div className="flex align-items-center gap-1">
                          <Image
                            style={{ objectFit: "contain" }}
                            src={require("../Assets/pakflag.png")}
                          />
                          <h2
                            style={{
                              color: "black",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            AaFood
                          </h2>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="float-right">
                          <h2 className="text-sm font-bold">$1</h2>
                        </div>
                      </Col>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <ColThreeCat />

        {/* <Col lg="12">
          <Row xs={2} md={4} lg={8} className="align-items-center webkit py-3">
            <Col>
              <div className="box-cate" style={{ background: "#1E75BB" }}>
                <div>
                  <Image
                    style={{ width: "50%" }}
                    src={require("../Assets/Fation.png")}
                  />
                </div>
                <h2 className="text-lg text-white pt-2">Apparel & Fashion</h2>
              </div>
            </Col>
            <Col>
              <div className="p-2 mmd-box">
                <div className=" bg-white rounded-sm text-left px-2 py-2">
                  <h3 className=" font-bold">Frozen Green Peas & Carrots</h3>
                  <h4 style={{ color: "#6D6E71" }}>500 no. (min MOQ)</h4>
                  <Image src={require("../Assets/mutton.png")} />
                  <div className="py-1 align-items-center d-flex">
                    <div className="inline mt-0">
                      <Col lg="9">
                        <div className="flex gap-1">
                          <Image
                            style={{ objectFit: "contain" }}
                            src={require("../Assets/pakflag.png")}
                          />
                          <h2
                            style={{
                              color: "black",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            AaFood
                          </h2>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="float-right">
                          <h2 className="text-sm font-bold">$1</h2>
                        </div>
                      </Col>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col>
              <div className="p-2 mmd-box">
                <div className=" bg-white rounded-sm text-left px-2 py-2">
                  <h3 className=" font-bold">Frozen Green Peas & Carrots</h3>
                  <h4 style={{ color: "#6D6E71" }}>500 no. (min MOQ)</h4>
                  <Image src={require("../Assets/mutton.png")} />
                  <div className="py-1 align-items-center d-flex">
                    <div className="inline mt-0">
                      <Col lg="9">
                        <div className="flex gap-1">
                          <Image
                            style={{ objectFit: "contain" }}
                            src={require("../Assets/pakflag.png")}
                          />
                          <h2
                            style={{
                              color: "black",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            AaFood
                          </h2>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="float-right">
                          <h2 className="text-sm font-bold">$1</h2>
                        </div>
                      </Col>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="p-2 mmd-box">
                <div className=" bg-white rounded-sm text-left px-2 py-2">
                  <h3 className=" font-bold">Frozen Green Peas & Carrots</h3>
                  <h4 style={{ color: "#6D6E71" }}>500 no. (min MOQ)</h4>
                  <Image src={require("../Assets/mutton.png")} />
                  <div className="py-1 align-items-center d-flex">
                    <div className="inline mt-0">
                      <Col lg="9">
                        <div className="flex gap-1">
                          <Image
                            style={{ objectFit: "contain" }}
                            src={require("../Assets/pakflag.png")}
                          />
                          <h2
                            style={{
                              color: "black",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            AaFood
                          </h2>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="float-right">
                          <h2 className="text-sm font-bold">$1</h2>
                        </div>
                      </Col>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col> */}
      </Row>
    </Container>
  );
};
