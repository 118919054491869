import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { ExportersCatMain } from "../ExportersMainComponents/ExportersCatMain";
import { MainExporter } from "../ExportersMainComponents/MainExporter";

export const BannerSec = () => {
  return (
    <Container>
      <Row>
        <Col lg="12">
          <div className="p-2 bg-overlay webkit my-2">
            <h2 className="text-white text-3xl font-bold">
              Agro & Agriculture
            </h2>
            <div className="justify-center dflex py-4">
              <div>
                <Button className="btnBan mx-2 mt-2 w-72">
                  Post Buy Requirement
                </Button>
              </div>
              <div>
                <Button className="mx-2 mt-2 w-72">Join Now Free</Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
