import React, { useState, Component, useEffect, useRef } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import {
  BrowserRouter as Route,
  Router,
  Link,
  Switch,
  useLocation,
} from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import { BiSearchAlt2 } from "react-icons/bi";
import { GrSearch } from "react-icons/gr";

export const Main = () => {
  const [isNavLink, setIsNavLink] = useState("Products");

  return (
    <Container fluid className="bg">
      <Container>
        <Row>
          <Col lg="12">
            <div className="py-5 text-center">
              <h1 className="text-4xl font-bold py-2">
                All the exporters under one roof
              </h1>
              <h2 className="text-2xl">
                Find and connect with the economical and
              </h2>
              <span className="text-2xl">
                the high quality product exporters
              </span>
              <div className="py-3 wrapper">
                <div className="searchBar drop">
                  <NavDropdown title={isNavLink} id="navbarScrollingDropdown1">
                    <Link
                      className="dropdown-item"
                      onClick={() => setIsNavLink("Products")}
                    >
                      Products
                    </Link>

                    <Link
                      className="dropdown-item"
                      onClick={() => setIsNavLink("Importers")}
                    >
                      Importers
                    </Link>

                    <Link
                      className="dropdown-item"
                      onClick={() => setIsNavLink("Exporters")}
                    >
                      Exporters
                    </Link>
                  </NavDropdown>
                  <input
                    id="searchQueryInput"
                    type="text"
                    name="searchQueryInput"
                    className="form-control"
                    placeholder="Search"
                  />
                  <button
                    id="searchQuerySubmit"
                    type="submit"
                    name="searchQuerySubmit"
                  >
                    <svg
                      style={{ width: "24px;", height: "24px" }}
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#666666"
                        d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div class="section--tags flex-section">
                <div class="flex-section--content">
                  <ul class="flex-section--carousel button-list">
                    <li>
                      <span
                        class="track button button--xs button--light semibold"
                        data-track-arguments="event,portada,click_palabra_buscador"
                      >
                        <BiSearchAlt2 />
                        <span>Fashion</span>
                      </span>
                    </li>
                    <li>
                      <span
                        class="track button button--xs button--light semibold"
                        data-track-arguments="event,portada,click_palabra_buscador"
                      >
                        <BiSearchAlt2 />
                        <span>Bags</span>
                      </span>
                    </li>
                    <li>
                      <span
                        class="track button button--xs button--light semibold"
                        data-track-arguments="event,portada,click_palabra_buscador"
                      >
                        <BiSearchAlt2 />
                        <span>Jewellery</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="12">
            <div className="py-3 text-center">
              <h2 className="text-2xl text-white" style={{ fontWeight: "500" }}>
                Trending Categories
              </h2>
              <Row xs={2} md={4} lg={6} className="py-4">
                <Col className="webkit">
                  <div className="circle">
                    <Image src={require("../Assets/cat1.png")} />
                  </div>
                  <h2 className="text-sm py-2 text-white">
                    Agro & Agriculture
                  </h2>
                </Col>
                <Col className="webkit">
                  <div className="circle">
                    <Image src={require("../Assets/cat2.png")} />
                  </div>
                  <h2 className="text-sm py-2 text-white">Apparel & Fashion</h2>
                </Col>
                <Col className="webkit">
                  <div className="circle">
                    <Image src={require("../Assets/cat3.png")} />
                  </div>
                  <h2 className="text-sm py-2 text-white">
                    Fashion Accessories
                  </h2>
                </Col>
                <Col className="webkit">
                  <div className="circle">
                    <Image src={require("../Assets/cat4.png")} />
                  </div>
                  <h2 className="text-sm py-2 text-white">
                    Garment Accessories
                  </h2>
                </Col>
                <Col className="webkit">
                  <div className="circle">
                    <Image src={require("../Assets/cat5.png")} />
                  </div>
                  <h2 className="text-sm py-2 text-white">Fine Jewellery</h2>
                </Col>
                <Col className="webkit">
                  <div className="circle">
                    <Image src={require("../Assets/cat6.png")} />
                  </div>
                  <h2 className="text-sm py-2 text-white">Handbags</h2>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
