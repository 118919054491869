import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";

export const FactoryTour = () => {
  return (
    <div className="mt-3 bg-white rounded-lg py-3">
      <div className="p-3">
        <h2 className="text-2xl" style={{ color: "#414042" }}>
          Factory Tour (Gallery)
        </h2>
      </div>
      <hr />
      <Container>
        <Row xs={2} md={4} lg={8}>
          <Col>
            <div className="p-3">
              <Image src={require("../Assets/FactoryTour.png")} />
            </div>
          </Col>
          <Col>
            <div className="p-3">
              <Image src={require("../Assets/FactoryTour.png")} />
            </div>
          </Col>
          <Col>
            <div className="p-3">
              <Image src={require("../Assets/FactoryTour.png")} />
            </div>
          </Col>
          <Col>
            {" "}
            <div className="p-3">
              <Image src={require("../Assets/FactoryTour.png")} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
