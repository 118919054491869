import React from "react";
import { Col, Container, Row, Button, Image } from "react-bootstrap";
import { ConnectTextile } from "../HomeComponents/ConnectTextile";
import { BannerExporterCol2 } from "./BannerExporterCol2";
import { CompanyExcuitives } from "./CompanyExcuitives";
import { CompanyKeywords } from "./CompanyKeywords";
import { ContactPerson } from "./ContactPerson";
import { FactoryTour } from "./FactoryTour";
import { LegalNoticed } from "./LegalNoticed";
import { ListOfProduct } from "./ListOfProduct";
import { OnlineShops } from "./OnlineShops";
import { ProductCategory } from "./ProductCategory";
import { ProductGallery1 } from "./ProductGallery1";
import { ProductsGallery2 } from "./ProductsGallery2";
import { SafeFood } from "./SafeFood";
import { ThreeCols } from "./ThreeCols";
import { BsFillPatchCheckFill, BsFillPatchMinusFill } from "react-icons/bs";
import { BsStarFill, BsStarHalf } from "react-icons/bs";

export const BannerExporter = () => {
  return (
    <>
      <Container fluid>
        <Container>
          <Row className="pt-4">
            <Col lg="9" className="pt-1 py-2">
              <div className="p-3 bg-white rounded-lg">
                <Row className="align-items-center">
                  <Col lg="6">
                    <Row className="align-items-center">
                      <Col className="p-3 webkit">
                        <Image
                          className="w-full"
                          src={require("../Assets/SF.png")}
                        />
                      </Col>
                      <Col className="p-3">
                        <div>
                          <h2 className="text-2xl">SafeFood</h2>
                          <div
                            className="ggp"
                            style={{ display: "inline-grid" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div className="flex gap-1 py-2 align-items-center">
                                <Image
                                  style={{ width: "15%", objectFit: "contain" }}
                                  src={require("../Assets/pakflag.png")}
                                />
                                <h2
                                  style={{ color: "#58595B", fontSize: "18px" }}
                                >
                                  Pakistan
                                </h2>
                              </div>
                              <div
                                className="text-center flex"
                                style={{ color: "#FFBC00" }}
                              >
                                <BsStarFill style={{ fontSize: "15" }} />
                                <BsStarFill style={{ fontSize: "15" }} />
                                <BsStarFill style={{ fontSize: "15" }} />
                                <BsStarFill style={{ fontSize: "15" }} />
                                <BsStarHalf style={{ fontSize: "15" }} />
                              </div>
                            </div>
                            <div>
                              <h2>Type of Business :</h2>
                              <div
                                style={{
                                  marginTop: "10px",
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "5px",
                                }}
                              >
                                <div
                                  style={{
                                    border: "1px solid #3bb44a",
                                    textAlign: "center",
                                    borderRadius: "20px",
                                  }}
                                >
                                  <h2>Exporters</h2>
                                </div>
                                <div
                                  style={{
                                    border: "1px solid #3bb44a",
                                    textAlign: "center",
                                    borderRadius: "20px",
                                  }}
                                >
                                  <h2>Exporters</h2>
                                </div>
                                <div
                                  style={{
                                    border: "1px solid #3bb44a",
                                    textAlign: "center",
                                    borderRadius: "20px",
                                  }}
                                >
                                  <h2>Exporters</h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col lg="3"></Col> */}
                  <Col lg="6">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "30px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <div
                            style={{
                              border: "1px solid #3bb44a",
                              padding: "3px",
                              borderRadius: "20px",
                              textAlign: "center",
                              display: "flex",
                              gap: "5px",
                              background: "white",
                            }}
                          >
                            <BsFillPatchCheckFill
                              style={{ color: "#3bb44a" }}
                            />
                            <h2 style={{ color: "#3bb44a", fontSize: "16px" }}>
                              VERIFIED
                            </h2>
                          </div>
                          {/* <div
                            style={{
                              border: "1px solid red",
                              padding: "3px",
                              borderRadius: "20px",
                              textAlign: "center",
                              display: "flex",
                              gap: "5px",
                              background: "white",
                            }}
                          >
                            <BsFillPatchMinusFill style={{ color: "red" }} />
                            <h2 style={{ color: "red", fontSize: "16px" }}>
                              UNVERIFIED
                            </h2>
                          </div> */}
                          <div style={{ textAlign: "center" }}>
                            <h2
                              style={{
                                fontSize: "13px",
                                color: "black",
                                fontFamily: "sans-serif",
                                fontWeight: "800",
                              }}
                            >
                              Member <br /> Since
                            </h2>
                          </div>
                          <div style={{ background: "black" }}>
                            <div className="years-rat">
                              <h2
                                style={{
                                  fontSize: "50px",
                                  color: "white",
                                  fontWeight: "500",
                                }}
                              >
                                3
                              </h2>
                            </div>
                            <div style={{ textAlign: "center" }}>
                              <h2
                                style={{
                                  fontSize: "13px",
                                  color: "#fff",
                                  fontFamily: "sans-serif",
                                  fontWeight: "800",
                                }}
                              >
                                YEAR
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ggp">
                        <div style={{ textAlign: "end" }}>
                          <h2>
                            Updated:{" "}
                            <span
                              style={{
                                color: "rgb(88, 89, 91)",
                                fontSize: "16px",
                              }}
                            >
                              08/05/2023
                            </span>
                          </h2>

                          {/* <br /> */}
                          <div style={{ marginTop: "10px" }}>
                            <h2>Own this listing?</h2>
                            <h2>update it!</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <SafeFood />
              <LegalNoticed />
              <ProductGallery1 />
              <ProductsGallery2 />
              <ListOfProduct />
              <ThreeCols />
              <OnlineShops />
              {/* <FactoryTour /> */}
              {/* <CompanyExcuitives /> */}
              <ContactPerson />
              <FactoryTour />
              {/* <ProductCategory /> */}
              <CompanyKeywords />
            </Col>
            <BannerExporterCol2 />
          </Row>
        </Container>
      </Container>
      {/* <ConnectTextile /> */}
    </>
  );
};
