import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

export const CreateAnAccount = () => {
  return (
    <Container fluid className="bg-acc">
      <Container>
        <div className="py-4 align-items-center webkit flex ddmm">
          <div>
            <h2 className="text-2xl">
              Create an account to post free products
            </h2>
          </div>
          <div>
            <Button className="btnMain">Sign up for free</Button>
          </div>
        </div>
      </Container>
    </Container>
  );
};
