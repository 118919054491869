import React from "react";
import { Col, Container, Image, Row, Button, FormCheck } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {
  BrowserRouter as Route,
  Router,
  Link,
  Switch,
  useNavigate,
} from "react-router-dom";

export const Login = () => {
  return (
    <Container fluid className="py-4">
      <Container>
        <Row className="py-4 align-items-center">
          <Col lg="6">
            <div className="p-2">
              <div className="box-shad">
                <div className="p-3 text-center">
                  <h2 className="text-3xl" style={{ color: "#3BB44A" }}>
                    Member
                    <span
                      className="border-b-2"
                      style={{ borderBottom: "3px solid #1E75BB" }}
                    >
                      {" "}
                      Login
                    </span>
                  </h2>
                </div>

                <div className="p-3">
                  <fieldset>
                    <Form.Label
                      htmlFor="inputEmail"
                      style={{ color: "#6D6E71" }}
                    >
                      Email
                    </Form.Label>
                    <Form.Control
                      className="py-2"
                      type="email"
                      placeholder="Enter Email Address Or Username"
                      id="inputEmail"
                      aria-describedby="emailHelpBlock"
                    />
                  </fieldset>
                  <fieldset>
                    <Form.Label
                      htmlFor="inputPassword"
                      style={{ color: "#6D6E71" }}
                    >
                      Password
                    </Form.Label>
                    <Form.Control
                      className="py-2"
                      type="Password"
                      placeholder="Enter password"
                      id="inputPassword"
                      aria-describedby="passwordHelpBlock"
                    />
                  </fieldset>
                  <p
                    className="float-right text-sm py-2"
                    style={{ color: "#3BB44A" }}
                  >
                    Forgot password?
                  </p>
                </div>
                <div className="p-3 webkit">
                  <Button
                    className="btnMain w-full py-3 my-4"
                    style={{ background: "" }}
                  >
                    Login
                  </Button>
                </div>
                <div className="p-3 webkit">
                  <h2 style={{ color: "#6D6E71", fontSize: "16px" }}>
                    New User ?{" "}
                    <Link to="/Signup">
                      <span style={{ color: "#1E75BB" }}>Sign up</span>
                    </Link>
                  </h2>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="6">
            <div className="p-3 webkit">
              <Image className="w-9/12" src={require("../Assets/login.png")} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="align-items-center">
          <Col lg="6">
            <div className="p-3 py-4">
              <h2 className="text-2xl" style={{ color: "#3BB44A" }}>
                Exporters Advantages
              </h2>
              <ul className="lili">
                <li>
                  <FormCheck />
                  Connect With Exporters Buyers - Directly
                </li>
                <li>
                  <FormCheck />
                  Find Your Textile Needs from Single Platform
                </li>
                <li>
                  <FormCheck />
                  Optimize Your Business Based on Market Demand
                </li>
                <li>
                  <FormCheck />
                  Global Visibility of Your Products / Online Store
                </li>
                <li>
                  <FormCheck />
                  We Display Your Products to The Right Category Buyer
                </li>
              </ul>
            </div>
          </Col>
          <Col lg="6">
            <div className="p-3 webkit">
              <Button className="btnMain w-9/12">Sign up for free</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
