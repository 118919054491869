import React from "react";
import { Col, Container, Row, Button, Image } from "react-bootstrap";

export const LegalNoticed = () => {
  return (
    <div className="mt-3 bg-white rounded-lg py-2">
      <div className="p-3">
        <h2 className="text-2xl" style={{ color: "#414042" }}>
          Legal Information
        </h2>
      </div>
      <hr />
      <div className="p-3">
        <ul>
          <li className="lilili">
            <Row>
              <Col lg="4" style={{ color: "#6D6E71" }}>
                Company Registration No
              </Col>
              <Col lg="1" className="text-right">
                :
              </Col>
              <Col lg="7">000-0000-0000-0000</Col>
            </Row>
          </li>
          <li className="lilili">
            <Row>
              <Col lg="4" style={{ color: "#6D6E71" }}>
                Year Established
              </Col>
              <Col lg="1" className="text-right">
                :
              </Col>
              <Col lg="7">2016</Col>
            </Row>
          </li>
          <li className="lilili">
            <Row>
              <Col lg="4" style={{ color: "#6D6E71" }}>
                Company Authorized Capital
              </Col>
              <Col lg="1" className="text-right">
                :
              </Col>
              <Col lg="7">xxxxxx</Col>
            </Row>
          </li>
          <li className="lilili">
            <Row>
              <Col lg="4" style={{ color: "#6D6E71" }}>
                Number of Employees
              </Col>
              <Col lg="1" className="text-right">
                :
              </Col>
              <Col lg="7">200</Col>
            </Row>
          </li>
          <li className="lilili">
            <Row>
              <Col lg="4" style={{ color: "#6D6E71" }}>
                ED I.D
              </Col>
              <Col lg="1" className="text-right">
                :
              </Col>
              <Col lg="7">1223456</Col>
            </Row>
          </li>
          <li className="lilili">
            <Row>
              <Col lg="4" style={{ color: "#6D6E71" }}>
                Legal Entity
              </Col>
              <Col lg="1" className="text-right">
                :
              </Col>
              <Col lg="7">Proprietor LLP LLC PVT. LTD. Ltd. Corp </Col>
            </Row>
          </li>
        </ul>
      </div>
    </div>
  );
};
