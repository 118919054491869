import React from "react";
import { Col, Container, Row, Image, Button } from "react-bootstrap";

export const MainProductCol2 = () => {
  return (
    <Col lg="3" className="pt-1 py-2">
      <div className="p-3 py-4 bxxx">
        <div className="text-left">
          <h2 className="text-lg">Get free quotes</h2>
          <h2 className="text-lg">
            from <span className="font-bold">Multiple Exporters</span>
          </h2>
        </div>
        <div className="webkit py-3">
          <h2 className="text-sm">Tell us your requirement</h2>
        </div>
        <Row>
          <Col lg="12">
            <fieldset>
              <input
                type="text"
                className="form-control"
                placeholder="Enter product"
              />
            </fieldset>
          </Col>
          <Col lg="12">
            <fieldset>
              <select className="form-control dropdown">
                <option value="grapefruit">Grapefruit</option>
                <option value="lime">Lime</option>
                <option value="coconut">Coconut</option>
                <option value="mango">Mango</option>
              </select>
            </fieldset>
          </Col>
          <Col lg="12">
            <fieldset>
              <input
                type="text"
                className="form-control"
                placeholder="Enter email"
              />
            </fieldset>
          </Col>
          <Col lg="12">
            <fieldset>
              <input
                type="text"
                className="form-control"
                placeholder="Enter your name"
              />
            </fieldset>
          </Col>
          <Col lg="12">
            <div className="pt-3 webkit">
              <Button className="btnMain w-full">Submit Requirement</Button>
            </div>
          </Col>
        </Row>
      </div>
      <div className="pt-3">
        <h2
          className="py-2 webkit text-2xl rounded-md"
          style={{ color: "white", background: "#3BB44A" }}
        >
          Top Products
        </h2>
        <Row className="pt-3">
          <Col lg="12">
            <div
              className="bg-white webkit py-3"
              style={{ border: "1px solid #3BB44A" }}
            >
              <Image className="w-8/12" src={require("../Assets/milk.png")} />
              <h2 className="text-sm text-black">Frozen Cauliflower</h2>
            </div>
          </Col>
          <Col lg="12">
            <div
              className="bg-white webkit py-3"
              style={{ border: "1px solid #3BB44A" }}
            >
              <Image className="w-8/12" src={require("../Assets/milk.png")} />
              <h2 className="text-sm text-black">Frozen Cauliflower</h2>
            </div>
          </Col>
        </Row>
      </div>
      <div className="pt-3">
        <h2
          className="py-2 webkit text-2xl rounded-md"
          style={{ color: "white", background: "#3BB44A" }}
        >
          Latest Product
        </h2>
        <Row className="pt-3">
          <Col lg="12">
            <div
              className="bg-white webkit py-3"
              style={{ border: "1px solid #3BB44A" }}
            >
              <Image className="w-8/12" src={require("../Assets/milk.png")} />
              <h2 className="text-sm text-black">Frozen Cauliflower</h2>
            </div>
          </Col>
          <Col lg="12">
            <div
              className="bg-white webkit py-3"
              style={{ border: "1px solid #3BB44A" }}
            >
              <Image className="w-8/12" src={require("../Assets/milk.png")} />
              <h2 className="text-sm text-black">Frozen Cauliflower</h2>
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  );
};
