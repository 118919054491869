import React, { useState, Component, useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import {
  BrowserRouter as Route,
  Router,
  Link,
  Switch,
  useLocation,
} from "react-router-dom";

export const MainCatComp = () => {
  const [isNavLink, setIsNavLink] = useState("Products");

  return (
    <Container fluid>
      <Container>
        <Row>
          <Col>
            <div className="py-3 wrapper">
              <div className="searchBar drop">
                <NavDropdown title={isNavLink} id="navbarScrollingDropdown1">
                  <Link
                    className="dropdown-item"
                    onClick={() => setIsNavLink("Products")}
                  >
                    Products
                  </Link>
                  <Link
                    className="dropdown-item"
                    onClick={() => setIsNavLink("Exporters")}
                  >
                    Exporters
                  </Link>
                  <Link
                    className="dropdown-item"
                    onClick={() => setIsNavLink("Importers")}
                  >
                    Importers
                  </Link>
                </NavDropdown>
                <input
                  id="searchQueryInput"
                  type="text"
                  name="searchQueryInput"
                  className="form-control"
                  placeholder="Search all products"
                />
                <button
                  id="searchQuerySubmit"
                  type="submit"
                  name="searchQuerySubmit"
                >
                  <svg
                    style={{ width: "24px;", height: "24px" }}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#666666"
                      d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
