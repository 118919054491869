import React from "react";
import { Container, Col, Row, Image } from "react-bootstrap";

export const MainBanner = () => {
  return (
    <Container>
      <Row>
        <Col lg="12">
          <div className="p-2">
            <Image src={require("../Assets/bg-image.png")} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};
