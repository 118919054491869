import React from "react";
import { BusinessPotential } from "../HomeComponents/BusinessPotential";
import { ChanelPartner } from "../HomeComponents/ChanelPartner";
import { ConnectTextile } from "../HomeComponents/ConnectTextile";
import { CreateAnAccount } from "../HomeComponents/CreateAnAccount";
import { Faq } from "../HomeComponents/Faq";
import { LatestSec } from "../HomeComponents/LatestSec";
import { Main } from "../HomeComponents/Main";
import { TopExporters } from "../HomeComponents/TopExporters";
import { TrendingProducts } from "../HomeComponents/TrendingProducts";
import { UpcomingExhibitions } from "../HomeComponents/UpcomingExhibitions";

export const Home = () => {
  return (
    <>
      <Main />
      <CreateAnAccount />
      <TrendingProducts />
      <TopExporters />
      <BusinessPotential />
      <ChanelPartner />
      <LatestSec />
      <UpcomingExhibitions />
      <Faq />
      <ConnectTextile />
    </>
  );
};
