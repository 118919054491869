import React from "react";
import { MainRecuirements } from "../BuyNewRecuirements/MainRecuirements";
import { ConnectTextile } from "../HomeComponents/ConnectTextile";

export const BuyRecuirements = () => {
  return (
    <>
      <MainRecuirements />
      <ConnectTextile />
    </>
  );
};
