import React from "react";
import { Col, Container, Row, Button, Image } from "react-bootstrap";

export const ContactPerson = () => {
  return (
    <div className="mt-3">
      <Row>
        <Col lg="12">
          <div className="p-3 bg-white rounded-lg py-3 mt-2">
            <h2 className="text-2xl pb-2" style={{ color: "#414042" }}>
              Associated With (Chambers & Associations)
            </h2>
            <hr />
            <Container>
              <Row xs={2} md={4} lg={8}>
                <Col>
                  <div className="p-3 text-center">
                    <Image src={require("../Assets/ProductGallery2.png")} />
                    <h2 className="pt-2">(Chamber Name)</h2>
                  </div>
                </Col>
                <Col>
                  <div className="p-3 text-center">
                    <Image src={require("../Assets/ProductGallery2.png")} />
                    <h2 className="pt-2">(Chamber Name)</h2>
                  </div>
                </Col>
                <Col>
                  <div className="p-3 text-center">
                    <Image src={require("../Assets/ProductGallery2.png")} />
                    <h2 className="pt-2">(Chamber Name)</h2>
                  </div>
                </Col>
                <Col>
                  {" "}
                  <div className="p-3 text-center">
                    <Image src={require("../Assets/ProductGallery2.png")} />
                    <h2 className="pt-2">(Chamber Name)</h2>
                  </div>
                </Col>
              </Row>
            </Container>
            {/* <div className="p-3">
              <Row>
                <Col lg="3" className="bg-gray-400">
                </Col>
                <Col lg="9">
                  <ul>
                    <li className="lilili">
                      <Row>
                        <Col lg="6" style={{ color: "#6D6E71" }}>
                          Name
                        </Col>
                        <Col lg="1" className="text-right">
                          :
                        </Col>
                        <Col lg="5">Muhammad Hamza</Col>
                      </Row>
                    </li>
                    <li className="lilili">
                      <Row>
                        <Col lg="6" style={{ color: "#6D6E71" }}>
                          Designation
                        </Col>
                        <Col lg="1" className="text-right">
                          :
                        </Col>
                        <Col lg="5">Manager</Col>
                      </Row>
                    </li>
                    <li className="lilili">
                      <Row>
                        <Col lg="6" style={{ color: "#6D6E71" }}>
                          Phone No
                        </Col>
                        <Col lg="1" className="text-right">
                          :
                        </Col>
                        <Col lg="5">021-4544522</Col>
                      </Row>
                    </li>
                    <li className="lilili">
                      <Row>
                        <Col lg="6" style={{ color: "#6D6E71" }}>
                          Mobile No
                        </Col>
                        <Col lg="1" className="text-right">
                          :
                        </Col>
                        <Col lg="5">0349-45423453</Col>
                      </Row>
                    </li>
                    <li className="lilili">
                      <Row>
                        <Col lg="6" style={{ color: "#6D6E71" }}>
                          Whatsapp
                        </Col>
                        <Col lg="1" className="text-right">
                          :
                        </Col>
                        <Col lg="5">0349-45423453</Col>
                      </Row>
                    </li>
                    <li className="lilili">
                      <Row>
                        <Col lg="6" style={{ color: "#6D6E71" }}>
                          Telegram / Messenger
                        </Col>
                        <Col lg="1" className="text-right">
                          :
                        </Col>
                        <Col lg="5">0349-45423453</Col>
                      </Row>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div> */}
          </div>
        </Col>

        {/* <Col lg="4">
            <div className="p-3 bg-white rounded-lg py-3 mt-2 h-96">
              <h2 className="text-2xl" style={{ color: "#414042" }}>
                Finanical Information
              </h2>
              <hr />
              <ul className="list-disc ddess">
                <li>Name of Banks</li>
                <li> Type of Currencies</li>
                <li>Annual Turnover</li>
              </ul>
            </div>
          </Col> */}
      </Row>
      <Row className="py-3">
        <Col lg="12">
          <div className="p-3 bg-white rounded-lg py-3 mt-2">
            <h2 className="text-2xl pb-2" style={{ color: "#414042" }}>
              Our Upcoming Exhibitions
            </h2>
            <hr />
            <Container>
              <Row xs={2} md={4} lg={8}>
                <Col>
                  <div className="p-3 text-center">
                    <Image src={require("../Assets/ProductGallery2.png")} />
                    <h2 className="pt-2">(Chamber Name)</h2>
                  </div>
                </Col>
                <Col>
                  <div className="p-3 text-center">
                    <Image src={require("../Assets/ProductGallery2.png")} />
                    <h2 className="pt-2">(Chamber Name)</h2>
                  </div>
                </Col>
                <Col>
                  <div className="p-3 text-center">
                    <Image src={require("../Assets/ProductGallery2.png")} />
                    <h2 className="pt-2">(Chamber Name)</h2>
                  </div>
                </Col>
                <Col>
                  {" "}
                  <div className="p-3 text-center">
                    <Image src={require("../Assets/ProductGallery2.png")} />
                    <h2 className="pt-2">(Chamber Name)</h2>
                  </div>
                </Col>
              </Row>
            </Container>
            {/* <div className="p-3">
              <Row>
                <Col lg="3" className="bg-gray-400">
                </Col>
                <Col lg="9">
                  <ul>
                    <li className="lilili">
                      <Row>
                        <Col lg="6" style={{ color: "#6D6E71" }}>
                          Name
                        </Col>
                        <Col lg="1" className="text-right">
                          :
                        </Col>
                        <Col lg="5">Muhammad Hamza</Col>
                      </Row>
                    </li>
                    <li className="lilili">
                      <Row>
                        <Col lg="6" style={{ color: "#6D6E71" }}>
                          Designation
                        </Col>
                        <Col lg="1" className="text-right">
                          :
                        </Col>
                        <Col lg="5">Manager</Col>
                      </Row>
                    </li>
                    <li className="lilili">
                      <Row>
                        <Col lg="6" style={{ color: "#6D6E71" }}>
                          Phone No
                        </Col>
                        <Col lg="1" className="text-right">
                          :
                        </Col>
                        <Col lg="5">021-4544522</Col>
                      </Row>
                    </li>
                    <li className="lilili">
                      <Row>
                        <Col lg="6" style={{ color: "#6D6E71" }}>
                          Mobile No
                        </Col>
                        <Col lg="1" className="text-right">
                          :
                        </Col>
                        <Col lg="5">0349-45423453</Col>
                      </Row>
                    </li>
                    <li className="lilili">
                      <Row>
                        <Col lg="6" style={{ color: "#6D6E71" }}>
                          Whatsapp
                        </Col>
                        <Col lg="1" className="text-right">
                          :
                        </Col>
                        <Col lg="5">0349-45423453</Col>
                      </Row>
                    </li>
                    <li className="lilili">
                      <Row>
                        <Col lg="6" style={{ color: "#6D6E71" }}>
                          Telegram / Messenger
                        </Col>
                        <Col lg="1" className="text-right">
                          :
                        </Col>
                        <Col lg="5">0349-45423453</Col>
                      </Row>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div> */}
          </div>
        </Col>

        {/* <Col lg="4">
            <div className="p-3 bg-white rounded-lg py-3 mt-2 h-96">
              <h2 className="text-2xl" style={{ color: "#414042" }}>
                Finanical Information
              </h2>
              <hr />
              <ul className="list-disc ddess">
                <li>Name of Banks</li>
                <li> Type of Currencies</li>
                <li>Annual Turnover</li>
              </ul>
            </div>
          </Col> */}
      </Row>

      <Row className="pb-3 align-items-center">
        <Col lg="7">
          <div className="p-3 box-height bg-white rounded-lg py-3 mt-2">
            <h2 className="text-2xl pb-2" style={{ color: "#414042" }}>
              Focal Person
            </h2>
            <hr />
            <div className="p-3">
              <Row>
                <Col lg="3" className="bg-gray-400">
                  {/* <Image src={require("../Assets/wheat.png")} /> */}
                </Col>
                <Col lg="9">
                  <ul>
                    <li className="lilili">
                      <Row>
                        <Col lg="6" style={{ color: "#6D6E71" }}>
                          Name
                        </Col>
                        <Col lg="1" className="text-right">
                          :
                        </Col>
                        <Col lg="5">Muhammad Hamza</Col>
                      </Row>
                    </li>
                    <li className="lilili">
                      <Row>
                        <Col lg="6" style={{ color: "#6D6E71" }}>
                          Designation
                        </Col>
                        <Col lg="1" className="text-right">
                          :
                        </Col>
                        <Col lg="5">Manager</Col>
                      </Row>
                    </li>
                    <li className="lilili">
                      <Row>
                        <Col lg="6" style={{ color: "#6D6E71" }}>
                          Phone No
                        </Col>
                        <Col lg="1" className="text-right">
                          :
                        </Col>
                        <Col lg="5">021-4544522</Col>
                      </Row>
                    </li>
                    <li className="lilili">
                      <Row>
                        <Col lg="6" style={{ color: "#6D6E71" }}>
                          Mobile No
                        </Col>
                        <Col lg="1" className="text-right">
                          :
                        </Col>
                        <Col lg="5">0349-45423453</Col>
                      </Row>
                    </li>
                    <li className="lilili">
                      <Row>
                        <Col lg="6" style={{ color: "#6D6E71" }}>
                          Whatsapp
                        </Col>
                        <Col lg="1" className="text-right">
                          :
                        </Col>
                        <Col lg="5">0349-45423453</Col>
                      </Row>
                    </li>
                    <li className="lilili">
                      <Row>
                        <Col lg="6" style={{ color: "#6D6E71" }}>
                          Telegram / Messenger
                        </Col>
                        <Col lg="1" className="text-right">
                          :
                        </Col>
                        <Col lg="5">0349-45423453</Col>
                      </Row>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col lg="5">
          <div className="p-3 box-height bg-white rounded-lg py-3 mt-2">
            <h2 className="text-2xl pb-2" style={{ color: "#414042" }}>
              Business Contact
            </h2>
            <hr />
            <div className="p-3">
              <ul>
                <li className="lilili">
                  <Row>
                    <Col lg="7" style={{ color: "#6D6E71" }}>
                      Social Media Link
                    </Col>
                    <Col lg="1" className="text-right">
                      :
                    </Col>
                    <Col lg="4">/asdvbsa</Col>
                  </Row>
                </li>
                <li className="lilili">
                  <Row>
                    <Col lg="7" style={{ color: "#6D6E71" }}>
                      Website URL
                    </Col>
                    <Col lg="1" className="text-right">
                      :
                    </Col>
                    <Col lg="4">www.sfood.com</Col>
                  </Row>
                </li>
                <li className="lilili">
                  <Row>
                    <Col lg="7" style={{ color: "#6D6E71" }}>
                      Company Address
                    </Col>
                    <Col lg="1" className="text-right">
                      :
                    </Col>
                    <Col lg="4">xxxx</Col>
                  </Row>
                </li>
                <li className="lilili">
                  <Row>
                    <Col lg="7" style={{ color: "#6D6E71" }}>
                      City
                    </Col>
                    <Col lg="1" className="text-right">
                      :
                    </Col>
                    <Col lg="4">Karachi</Col>
                  </Row>
                </li>
                <li className="lilili">
                  <Row>
                    <Col lg="7" style={{ color: "#6D6E71" }}>
                      Country
                    </Col>
                    <Col lg="1" className="text-right">
                      :
                    </Col>
                    <Col lg="4">Pakistan</Col>
                  </Row>
                </li>
              </ul>
            </div>
          </div>
        </Col>
        {/* <Col lg="4">
            <div className="p-3 bg-white rounded-lg py-3 mt-2 h-96">
              <h2 className="text-2xl" style={{ color: "#414042" }}>
                Finanical Information
              </h2>
              <hr />
              <ul className="list-disc ddess">
                <li>Name of Banks</li>
                <li> Type of Currencies</li>
                <li>Annual Turnover</li>
              </ul>
            </div>
          </Col> */}
      </Row>
    </div>
  );
};
