import React from "react";
import { Col, Container, Row, Image, Button } from "react-bootstrap";
import { MainProductCol2 } from "./MainProductCol2";

export const MainProduct = () => {
  const data = [
    { image: "../Assets/agriculture.png", title1: "Agro & Agriculture" },
  ];
  const data1 = [
    {
      heading: "Frozen Green Peas & Carrots",
      describe: "500 no. (min MOQ)",
      image: "../Assets/mutton.png",
      imageflag: "../Assets/pakflag.png",
      cityName: "AaFood",
      price: "$1",
    },
    {
      heading: "Frozen Green Peas & Carrots",
      describe: "500 no. (min MOQ)",
      image: "../Assets/mutton.png",
      imageflag: "../Assets/pakflag.png",
      cityName: "AaFood",
      price: "$1",
    },
  ];
  return (
    <Container>
      <div className="py-3 webkit">
        <h2 className="text-2xl font-bold" style={{ color: "#1E75BB" }}>
          Product
        </h2>
      </div>
      <Row>
        <Col lg="9">
          <Row>
            <Col>
              <Row xs={2} md={4} lg={8} className="align-items-center webkit">
                <Col>
                  <div className="box-cate" style={{ background: "#3BB44A" }}>
                    <div>
                      <Image
                        style={{ width: "50%" }}
                        src={require("../Assets/agriculture.png")}
                      />
                    </div>
                    <h2 className="text-lg text-white pt-2">
                      Agro & Agriculture
                    </h2>
                  </div>
                </Col>
                <Col>
                  <div className="p-2 mmd-box">
                    <div className=" bg-white rounded-sm text-left px-2 py-2">
                      <h3 className=" font-bold">
                        Frozen Green Peas & Carrots
                      </h3>
                      <h4 style={{ color: "#6D6E71" }}>500 no. (min MOQ)</h4>
                      <Image src={require("../Assets/mutton.png")} />
                      <div className="py-1 align-items-center d-flex">
                        <div className="inline mt-0">
                          <Col lg="9">
                            <div className="flex align-items-center gap-1">
                              <Image
                                style={{ objectFit: "contain" }}
                                src={require("../Assets/pakflag.png")}
                              />
                              <h2
                                style={{
                                  color: "black",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                AaFood
                              </h2>
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="float-right">
                              <h2 className="text-sm font-bold">$1</h2>
                            </div>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="p-2 mmd-box">
                    <div className=" bg-white rounded-sm text-left px-2 py-2">
                      <h3 className=" font-bold">
                        Frozen Green Peas & Carrots
                      </h3>
                      <h4 style={{ color: "#6D6E71" }}>500 no. (min MOQ)</h4>
                      <Image src={require("../Assets/mutton.png")} />
                      <div className="py-1 align-items-center d-flex">
                        <div className="inline mt-0">
                          <Col lg="9">
                            <div className="flex align-items-center gap-1">
                              <Image
                                style={{ objectFit: "contain" }}
                                src={require("../Assets/pakflag.png")}
                              />
                              <h2
                                style={{
                                  color: "black",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                AaFood
                              </h2>
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="float-right">
                              <h2 className="text-sm font-bold">$1</h2>
                            </div>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col>
                  <div className="p-2 mmd-box">
                    <div className=" bg-white rounded-sm text-left px-2 py-2">
                      <h3 className=" font-bold">
                        Frozen Green Peas & Carrots
                      </h3>
                      <h4 style={{ color: "#6D6E71" }}>500 no. (min MOQ)</h4>
                      <Image src={require("../Assets/mutton.png")} />
                      <div className="py-1 align-items-center d-flex">
                        <div className="inline mt-0">
                          <Col lg="9">
                            <div className="flex align-items-center gap-1">
                              <Image
                                style={{ objectFit: "contain" }}
                                src={require("../Assets/pakflag.png")}
                              />
                              <h2
                                style={{
                                  color: "black",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                AaFood
                              </h2>
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="float-right">
                              <h2 className="text-sm font-bold">$1</h2>
                            </div>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col lg="12">
              <Row
                xs={2}
                md={4}
                lg={8}
                className="align-items-center webkit py-3"
              >
                <Col>
                  <div className="box-cate" style={{ background: "#1E75BB" }}>
                    <div>
                      <Image
                        style={{ width: "50%" }}
                        src={require("../Assets/Fation.png")}
                      />
                    </div>
                    <h2 className="text-lg text-white pt-2">
                      Apparel & Fashion
                    </h2>
                  </div>
                </Col>
                <Col>
                  <div className="p-2 mmd-box">
                    <div className=" bg-white rounded-sm text-left px-2 py-2">
                      <h3 className=" font-bold">
                        Frozen Green Peas & Carrots
                      </h3>
                      <h4 style={{ color: "#6D6E71" }}>500 no. (min MOQ)</h4>
                      <Image src={require("../Assets/mutton.png")} />
                      <div className="py-1 align-items-center d-flex">
                        <div className="inline mt-0">
                          <Col lg="9">
                            <div className="flex gap-1">
                              <Image
                                style={{ objectFit: "contain" }}
                                src={require("../Assets/pakflag.png")}
                              />
                              <h2
                                style={{
                                  color: "black",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                AaFood
                              </h2>
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="float-right">
                              <h2 className="text-sm font-bold">$1</h2>
                            </div>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col>
                  <div className="p-2 mmd-box">
                    <div className=" bg-white rounded-sm text-left px-2 py-2">
                      <h3 className=" font-bold">
                        Frozen Green Peas & Carrots
                      </h3>
                      <h4 style={{ color: "#6D6E71" }}>500 no. (min MOQ)</h4>
                      <Image src={require("../Assets/mutton.png")} />
                      <div className="py-1 align-items-center d-flex">
                        <div className="inline mt-0">
                          <Col lg="9">
                            <div className="flex gap-1">
                              <Image
                                style={{ objectFit: "contain" }}
                                src={require("../Assets/pakflag.png")}
                              />
                              <h2
                                style={{
                                  color: "black",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                AaFood
                              </h2>
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="float-right">
                              <h2 className="text-sm font-bold">$1</h2>
                            </div>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="p-2 mmd-box">
                    <div className=" bg-white rounded-sm text-left px-2 py-2">
                      <h3 className=" font-bold">
                        Frozen Green Peas & Carrots
                      </h3>
                      <h4 style={{ color: "#6D6E71" }}>500 no. (min MOQ)</h4>
                      <Image src={require("../Assets/mutton.png")} />
                      <div className="py-1 align-items-center d-flex">
                        <div className="inline mt-0">
                          <Col lg="9">
                            <div className="flex gap-1">
                              <Image
                                style={{ objectFit: "contain" }}
                                src={require("../Assets/pakflag.png")}
                              />
                              <h2
                                style={{
                                  color: "black",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                AaFood
                              </h2>
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="float-right">
                              <h2 className="text-sm font-bold">$1</h2>
                            </div>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Container style={{ background: "#1E75BB", borderRadius: "10px" }}>
            <div className="py-4 align-items-center webkit flex ddmm">
              <div>
                <h2 className="text-2xl text-white">
                  Connect With Textile B2B Network
                </h2>
              </div>
              <div>
                <button class="btn btn-outline-light p-2 bg-green-500">
                  Sign up for free
                </button>
              </div>
            </div>
          </Container>
        </Col>
        <MainProductCol2 />
      </Row>
    </Container>
  );
};
