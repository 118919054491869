import React from "react";
import { BannerSec } from "../CategoryComponents/BannerSec";
import { MainCatComp } from "../CategoryComponents/MainCatComp";
import { ProductCategory } from "../CategoryComponents/ProductCategory";
import { Sidemenu } from "../CategoryComponents/Sidemenu";
import { ConnectTextile } from "../HomeComponents/ConnectTextile";

export const Category = () => {
  return (
    <>
      <MainCatComp />
      <BannerSec />
      <Sidemenu />
      <ConnectTextile />
      {/* <ProductCategory /> */}
    </>
  );
};
