import React from "react";
import { Col, Container, Row, Image, Button } from "react-bootstrap";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { BsStarFill, BsStarHalf } from "react-icons/bs";
import { Col2MainExporter } from "./Col2MainExporter";
import { Link } from "react-router-dom";
export const ExportersCatMain = () => {
  return (
    <Container>
      <div className="py-3 webkit">
        <h2 className="text-2xl font-bold" style={{ color: "#1E75BB" }}>
          Exporters
        </h2>
      </div>
      <Row>
        <Col lg="9">
          <Row lg={12} className="align-items-center webkit">
            <Col lg="6">
              <div className="p-2">
                <div
                  className=" rounded-lg text-left px-2 py-2"
                  style={{ background: "rgb(255 248 186 / 63%)" }}
                >
                  <div className="py-1 align-items-center contents">
                    <div className="inline mt-0">
                      <Col lg="12">
                        <div className="flex justify-between align-items-center gap-1">
                          <Link to="/ExportersCategory">
                            <div className="flex gap-2 align-items-center">
                              <Image
                                className=" rounded-full"
                                style={{
                                  objectFit: "cover",
                                  width: "60px",
                                  borderRadius: "200px",
                                  height: "60px",
                                }}
                                src={require("../Assets/sfLogo.png")}
                              />
                              <div className="d-block">
                                <h2
                                  style={{
                                    color: "black",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  SafeFood
                                </h2>
                                <div className="flex align-items-center gap-1">
                                  <Image
                                    style={{
                                      width: "10%",
                                      objectFit: "contain",
                                    }}
                                    src={require("../Assets/pakflag.png")}
                                  />
                                  <h2
                                    style={{
                                      color: "black",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    Pakistan
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </Link>
                          <div style={{ textAlign: "-webkit-right" }}>
                            <IoMdCheckmarkCircleOutline
                              style={{ color: "#3BB44A", fontSize: "22px" }}
                            />
                            <div
                              className="text-center flex"
                              style={{ color: "#FFBC00" }}
                            >
                              <BsStarFill style={{ fontSize: "15" }} />
                              <BsStarFill style={{ fontSize: "15" }} />
                              <BsStarFill style={{ fontSize: "15" }} />
                              <BsStarFill style={{ fontSize: "15" }} />
                              <BsStarHalf style={{ fontSize: "15" }} />
                            </div>
                            {/* <Bs0Circle */}
                          </div>
                        </div>
                      </Col>
                      {/* <Col lg="3">
                        <div className="float-right">
                           <h2 className="text-sm font-bold">$1</h2>
                          
                        </div>
                        {" "}
                      </Col> */}
                    </div>
                  </div>
                  <div
                    className="p-2 rounded-xl my-3"
                    style={{ border: "dashed rgb(109, 110, 113)" }}
                  >
                    <h2 className="text-lg" style={{ color: "#414042" }}>
                      Product Title
                    </h2>
                  </div>
                  {/* <input
                    className="form-control"
                    type="text"
                    disabled
                    placeholder="Product Title"
                  /> */}
                  <Row>
                    <Col>
                      <div className="block py-3">
                        <h2
                          className="float-right"
                          style={{ color: "#6D6E71", fontSize: "12px" }}
                        >
                          (MOQ/Unit)
                        </h2>
                        <h2 style={{ color: "#414042", fontSize: "12px" }}>
                          Price/Unit
                        </h2>
                      </div>
                      <p className="text-sm" style={{ color: "#6D6E71" }}>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                        dolore magna aliquam erat volutpat. Ut wisi enim ad
                        minim veniam,
                      </p>
                    </Col>
                    <Col>
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col lg="8">
                            <Image src={require("../Assets/export.png")} />
                          </Col>
                          <Col lg="4">
                            <div className="block exportC">
                              <Image src={require("../Assets/export1.png")} />
                              <Image src={require("../Assets/export2.png")} />
                              <Image src={require("../Assets/export3.png")} />
                              <Image src={require("../Assets/export4.png")} />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row xs={2} md={4} lg={8} className="justify-center py-3">
                    <Col lg={4}>
                      <button
                        type="submit"
                        class="btnexp btn-outline-light mb-4 w-full p-2 bg-green-500"
                      >
                        Show Phone Number
                      </button>
                    </Col>
                    <Col lg={4}>
                      <button
                        type="submit"
                        class="btnexp btn-outline-light w-full mb-4 p-2 bg-green-500"
                      >
                        Send an email
                      </button>
                    </Col>
                    <Col lg={4}>
                      <button
                        type="submit"
                        class="btnexp btn-outline-light w-full mb-4 p-2 bg-green-500"
                      >
                        Get Latest Price
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div className="p-2">
                <div
                  className=" rounded-lg text-left px-2 py-2"
                  style={{ background: "rgb(255 248 186 / 63%)" }}
                >
                  <div className="py-1 align-items-center contents">
                    <div className="inline mt-0">
                      <Col lg="12">
                        <div className="flex justify-between align-items-center gap-1">
                          <Link to="/ExportersCategory">
                            <div className="flex gap-2 align-items-center">
                              <Image
                                className=" rounded-full"
                                style={{
                                  objectFit: "cover",
                                  width: "60px",
                                  borderRadius: "200px",
                                  height: "60px",
                                }}
                                src={require("../Assets/sfLogo.png")}
                              />
                              <div className="d-block">
                                <h2
                                  style={{
                                    color: "black",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  SafeFood
                                </h2>
                                <div className="flex align-items-center gap-1">
                                  <Image
                                    style={{
                                      width: "10%",
                                      objectFit: "contain",
                                    }}
                                    src={require("../Assets/pakflag.png")}
                                  />
                                  <h2
                                    style={{
                                      color: "black",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    Pakistan
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </Link>
                          <div style={{ textAlign: "-webkit-right" }}>
                            <IoMdCheckmarkCircleOutline
                              style={{ color: "#3BB44A", fontSize: "22px" }}
                            />
                            <div
                              className="text-center flex"
                              style={{ color: "#FFBC00" }}
                            >
                              <BsStarFill style={{ fontSize: "15" }} />
                              <BsStarFill style={{ fontSize: "15" }} />
                              <BsStarFill style={{ fontSize: "15" }} />
                              <BsStarFill style={{ fontSize: "15" }} />
                              <BsStarHalf style={{ fontSize: "15" }} />
                            </div>
                            {/* <Bs0Circle */}
                          </div>
                        </div>
                      </Col>
                      {/* <Col lg="3">
                        <div className="float-right">
                           <h2 className="text-sm font-bold">$1</h2>
                          
                        </div>
                        {" "}
                      </Col> */}
                    </div>
                  </div>
                  <div
                    className="p-2 rounded-xl my-3"
                    style={{ border: "dashed rgb(109, 110, 113)" }}
                  >
                    <h2 className="text-lg" style={{ color: "#414042" }}>
                      Product Title
                    </h2>
                  </div>
                  {/* <input
                    className="form-control"
                    type="text"
                    disabled
                    placeholder="Product Title"
                  /> */}
                  <Row>
                    <Col>
                      <div className="block py-3">
                        <h2
                          className="float-right"
                          style={{ color: "#6D6E71", fontSize: "12px" }}
                        >
                          (MOQ/Unit)
                        </h2>
                        <h2 style={{ color: "#414042", fontSize: "12px" }}>
                          Price/Unit
                        </h2>
                      </div>
                      <p className="text-sm" style={{ color: "#6D6E71" }}>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                        dolore magna aliquam erat volutpat. Ut wisi enim ad
                        minim veniam,
                      </p>
                    </Col>
                    <Col>
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col lg="8">
                            <Image src={require("../Assets/export.png")} />
                          </Col>
                          <Col lg="4">
                            <div className="block exportC">
                              <Image src={require("../Assets/export1.png")} />
                              <Image src={require("../Assets/export2.png")} />
                              <Image src={require("../Assets/export3.png")} />
                              <Image src={require("../Assets/export4.png")} />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row xs={2} md={4} lg={8} className="justify-center py-3">
                    <Col lg={4}>
                      <button
                        type="submit"
                        class="btnexp btn-outline-light mb-4 w-full p-2 bg-green-500"
                      >
                        Show Phone Number
                      </button>
                    </Col>
                    <Col lg={4}>
                      <button
                        type="submit"
                        class="btnexp btn-outline-light w-full mb-4 p-2 bg-green-500"
                      >
                        Send an email
                      </button>
                    </Col>
                    <Col lg={4}>
                      <button
                        type="submit"
                        class="btnexp btn-outline-light w-full mb-4 p-2 bg-green-500"
                      >
                        Get Latest Price
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Container style={{ background: "#1E75BB", borderRadius: "10px" }}>
            <div className="py-4 align-items-center webkit flex ddmm">
              <div>
                <h2 className="text-2xl text-white">
                  Connect With Textile B2B Network
                </h2>
              </div>
              <div>
                <button class="btn btn-outline-light p-2 bg-green-500">
                  Sign up for free
                </button>
              </div>
            </div>
          </Container>
          <Row lg={12} className="align-items-center webkit">
            <Col lg="6">
              <div className="p-2">
                <div className="bg-white rounded-lg text-left px-2 py-2">
                  <div className="py-1 align-items-center contents">
                    <div className="inline mt-0">
                      <Col lg="12">
                        <div className="flex justify-between align-items-center gap-1">
                          <div className="flex gap-2 align-items-center">
                            <div className="d-block">
                              <h2
                                style={{
                                  color: "black",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                ADZeeshan
                              </h2>
                              <div className="flex align-items-center gap-1">
                                <Image
                                  style={{
                                    width: "10%",
                                    objectFit: "contain",
                                  }}
                                  src={require("../Assets/pakflag.png")}
                                />
                                <h2
                                  style={{
                                    color: "black",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  Pakistan
                                </h2>
                              </div>
                            </div>
                          </div>
                          <div style={{ textAlign: "-webkit-right" }}>
                            <Button className="btnMain">UNVERIFIED</Button>
                            {/* <Bs0Circle */}
                          </div>
                        </div>
                      </Col>
                      {/* <Col lg="3">
                        <div className="float-right">
                           <h2 className="text-sm font-bold">$1</h2>
                          
                        </div>
                        {" "}
                      </Col> */}
                    </div>
                  </div>
                  <Row>
                    <Col lg="7">
                      <div
                        className="p-2 rounded-xl my-3"
                        style={{ border: "dashed rgb(109, 110, 113)" }}
                      >
                        <h2 className="text-lg" style={{ color: "#414042" }}>
                          Product Title
                        </h2>
                      </div>
                      <div className="block py-3">
                        <h2
                          className="float-right"
                          style={{ color: "#6D6E71", fontSize: "12px" }}
                        >
                          (MOQ/Unit)
                        </h2>
                        <h2 style={{ color: "#414042", fontSize: "12px" }}>
                          Price/Unit
                        </h2>
                      </div>
                      <p className="text-sm" style={{ color: "#6D6E71" }}>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                        dolore magna aliquam erat volutpat. Ut wisi enim ad
                        minim veniam,
                      </p>
                    </Col>
                    <Col lg="5">
                      <div className="block exportC">
                        <Image src={require("../Assets/prodcast.png")} />
                      </div>
                    </Col>
                  </Row>

                  {/* <input
                    className="form-control"
                    type="text"
                    disabled
                    placeholder="Product Title"
                  /> */}

                  <Row
                    xs={2}
                    md={4}
                    lg={8}
                    className="justify-center py-3 align-items-center"
                  >
                    {/* <Col lg="1"></Col> */}
                    <Col lg={5}>
                      <button
                        type="submit"
                        class="btnexp btn-outline-light mb-4 w-full p-2 bg-green-500"
                      >
                        Show an email
                      </button>
                    </Col>
                    <Col lg={5}>
                      <button
                        type="submit"
                        class="btnexp btn-outline-light w-full mb-4 p-2 bg-green-500"
                      >
                        Submit Requirement Get Verified Exporters
                      </button>
                    </Col>
                    {/* <Col lg="1"></Col> */}
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div className="p-2">
                <div className="bg-white rounded-lg text-left px-2 py-2">
                  <div className="py-1 align-items-center contents">
                    <div className="inline mt-0">
                      <Col lg="12">
                        <div className="flex justify-between align-items-center gap-1">
                          <div className="flex gap-2 align-items-center">
                            <div className="d-block">
                              <h2
                                style={{
                                  color: "black",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                ADZeeshan
                              </h2>
                              <div className="flex align-items-center gap-1">
                                <Image
                                  style={{
                                    width: "10%",
                                    objectFit: "contain",
                                  }}
                                  src={require("../Assets/pakflag.png")}
                                />
                                <h2
                                  style={{
                                    color: "black",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  Pakistan
                                </h2>
                              </div>
                            </div>
                          </div>
                          <div style={{ textAlign: "-webkit-right" }}>
                            <Button className="btnMain">UNVERIFIED</Button>
                            {/* <Bs0Circle */}
                          </div>
                        </div>
                      </Col>
                      {/* <Col lg="3">
                        <div className="float-right">
                           <h2 className="text-sm font-bold">$1</h2>
                          
                        </div>
                        {" "}
                      </Col> */}
                    </div>
                  </div>
                  <Row>
                    <Col lg="7">
                      <div
                        className="p-2 rounded-xl my-3"
                        style={{ border: "dashed rgb(109, 110, 113)" }}
                      >
                        <h2 className="text-lg" style={{ color: "#414042" }}>
                          Product Title
                        </h2>
                      </div>
                      <div className="block py-3">
                        <h2
                          className="float-right"
                          style={{ color: "#6D6E71", fontSize: "12px" }}
                        >
                          (MOQ/Unit)
                        </h2>
                        <h2 style={{ color: "#414042", fontSize: "12px" }}>
                          Price/Unit
                        </h2>
                      </div>
                      <p className="text-sm" style={{ color: "#6D6E71" }}>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                        dolore magna aliquam erat volutpat. Ut wisi enim ad
                        minim veniam,
                      </p>
                    </Col>
                    <Col lg="5">
                      <div className="block exportC">
                        <Image src={require("../Assets/prodcast.png")} />
                      </div>
                    </Col>
                  </Row>

                  {/* <input
                    className="form-control"
                    type="text"
                    disabled
                    placeholder="Product Title"
                  /> */}

                  <Row
                    xs={2}
                    md={4}
                    lg={8}
                    className="justify-center py-3 align-items-center"
                  >
                    {/* <Col lg="1"></Col> */}
                    <Col lg={5}>
                      <button
                        type="submit"
                        class="btnexp btn-outline-light mb-4 w-full p-2 bg-green-500"
                      >
                        Show an email
                      </button>
                    </Col>
                    <Col lg={5}>
                      <button
                        type="submit"
                        class="btnexp btn-outline-light w-full mb-4 p-2 bg-green-500"
                      >
                        Submit Requirement Get Verified Exporters
                      </button>
                    </Col>
                    {/* <Col lg="1"></Col> */}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col2MainExporter />

        {/* <Col lg="12">
          <Row xs={2} md={4} lg={8} className="align-items-center webkit py-3">
            <Col>
              <div className="box-cate" style={{ background: "#1E75BB" }}>
                <div>
                  <Image
                    style={{ width: "50%" }}
                    src={require("../Assets/Fation.png")}
                  />
                </div>
                <h2 className="text-lg text-white pt-2">Apparel & Fashion</h2>
              </div>
            </Col>
            <Col>
              <div className="p-2 mmd-box">
                <div className=" bg-white rounded-sm text-left px-2 py-2">
                  <h3 className=" font-bold">Frozen Green Peas & Carrots</h3>
                  <h4 style={{ color: "#6D6E71" }}>500 no. (min MOQ)</h4>
                  <Image src={require("../Assets/mutton.png")} />
                  <div className="py-1 align-items-center d-flex">
                    <div className="inline mt-0">
                      <Col lg="9">
                        <div className="flex gap-1">
                          <Image
                            style={{ objectFit: "contain" }}
                            src={require("../Assets/pakflag.png")}
                          />
                          <h2
                            style={{
                              color: "black",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            AaFood
                          </h2>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="float-right">
                          <h2 className="text-sm font-bold">$1</h2>
                        </div>
                      </Col>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col>
              <div className="p-2 mmd-box">
                <div className=" bg-white rounded-sm text-left px-2 py-2">
                  <h3 className=" font-bold">Frozen Green Peas & Carrots</h3>
                  <h4 style={{ color: "#6D6E71" }}>500 no. (min MOQ)</h4>
                  <Image src={require("../Assets/mutton.png")} />
                  <div className="py-1 align-items-center d-flex">
                    <div className="inline mt-0">
                      <Col lg="9">
                        <div className="flex gap-1">
                          <Image
                            style={{ objectFit: "contain" }}
                            src={require("../Assets/pakflag.png")}
                          />
                          <h2
                            style={{
                              color: "black",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            AaFood
                          </h2>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="float-right">
                          <h2 className="text-sm font-bold">$1</h2>
                        </div>
                      </Col>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="p-2 mmd-box">
                <div className=" bg-white rounded-sm text-left px-2 py-2">
                  <h3 className=" font-bold">Frozen Green Peas & Carrots</h3>
                  <h4 style={{ color: "#6D6E71" }}>500 no. (min MOQ)</h4>
                  <Image src={require("../Assets/mutton.png")} />
                  <div className="py-1 align-items-center d-flex">
                    <div className="inline mt-0">
                      <Col lg="9">
                        <div className="flex gap-1">
                          <Image
                            style={{ objectFit: "contain" }}
                            src={require("../Assets/pakflag.png")}
                          />
                          <h2
                            style={{
                              color: "black",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            AaFood
                          </h2>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="float-right">
                          <h2 className="text-sm font-bold">$1</h2>
                        </div>
                      </Col>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col> */}
      </Row>
    </Container>
  );
};
