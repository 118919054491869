import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Col, Container, Row, Image, FormCheck, Button } from "react-bootstrap";
import { BsFacebook, BsLinkedin } from "react-icons/bs";
import {
  BrowserRouter as Route,
  Router,
  Link,
  Switch,
  useNavigate,
} from "react-router-dom";
import "react-tabs/style/react-tabs.css";

export const Signup = () => {
  return (
    <Container fluid className="py-4">
      <Container>
        <Row className="py-4 align-items-center">
          <Col lg="6">
            <div className="p-2">
              <div className="box-shad">
                <div className="p-3 text-center">
                  <h2 className="text-3xl" style={{ color: "#3BB44A" }}>
                    <span
                      className="border-b-2"
                      style={{ borderBottom: "3px solid #1E75BB" }}
                    >
                      {" "}
                      Sign Up{" "}
                    </span>
                    For Free
                  </h2>
                  <h2 className="text-sm text-black pt-3">
                    Add Your Business to the Exporters Directory
                  </h2>
                  {/* <h2 className="text-sm text-black">
                    Directory & B2B Marketplace
                  </h2> */}
                </div>
                <div className="dflex">
                  <div>
                    <Tabs className="webkit" id="tablet">
                      <TabList className="webkit py-4 d-flex-for-res">
                        <Tab>
                          <div className="mm w-6/12">
                            <Image
                              className="p-3"
                              src={require("../Assets/signuptab1.png")}
                            />
                            <p className="text-sm" style={{ color: "#6D6E71" }}>
                              I'm a Supplier
                            </p>
                          </div>
                        </Tab>
                        <Tab>
                          <div className="mm w-6/12">
                            <Image
                              className="p-3"
                              src={require("../Assets/signuptab2.png")}
                            />
                            <p className="text-sm" style={{ color: "#6D6E71" }}>
                              I'm a Buyer
                            </p>
                          </div>
                        </Tab>
                      </TabList>

                      <TabPanel>
                        <div className="p-3">
                          <Row>
                            <Col lg="12">
                              <fieldset>
                                {/* <label style={{ width: "100%" }}>First name</label> */}
                                <input
                                  style={{ width: "100%" }}
                                  className="form-control"
                                  type={"text"}
                                  name="Fname"
                                  // onChange={(e) =>
                                  //   setFreelancerSignUpData({
                                  //     ...freelancerSignupData,
                                  //     firstName: e.target.value,
                                  //   })
                                  // }
                                  //   value={user.name}
                                  //   onChange={getUserData}
                                  placeholder="Business Name"
                                  required
                                />
                              </fieldset>
                            </Col>
                            <Col lg="12">
                              <fieldset>
                                {/* <label style={{ width: "100%" }}>First name</label> */}
                                <input
                                  style={{ width: "100%" }}
                                  className="form-control"
                                  type={"text"}
                                  name="Lname"
                                  // onChange={(e) =>
                                  //   setFreelancerSignUpData({
                                  //     ...freelancerSignupData,
                                  //     lastName: e.target.value,
                                  //   })
                                  // }
                                  //   value={user.name}
                                  //   onChange={getUserData}
                                  placeholder="Select Country"
                                  required
                                />
                              </fieldset>
                            </Col>
                            <Col lg="12">
                              <fieldset>
                                {/* <label style={{ width: "100%" }}>First name</label> */}
                                <input
                                  style={{ width: "100%" }}
                                  className="form-control"
                                  type={"Email"}
                                  name="email"
                                  // onChange={(e) =>
                                  //   setFreelancerSignUpData({
                                  //     ...freelancerSignupData,
                                  //     email: e.target.value,
                                  //   })
                                  // }
                                  //   value={user.name}
                                  //   onChange={getUserData}
                                  placeholder="Enter Full Name"
                                  required
                                />
                              </fieldset>
                            </Col>

                            <Col lg="12">
                              <fieldset>
                                <div className="input-group">
                                  {/* <label style={{ width: "100%" }}>First name</label> */}
                                  <input
                                    style={{ width: "100%" }}
                                    className="form-control"
                                    type={"number"}
                                    name="password"
                                    // onChange={(e) =>
                                    //   setFreelancerSignUpData({
                                    //     ...freelancerSignupData,
                                    //     password: e.target.value,
                                    //   })
                                    // }
                                    //   value={user.name}
                                    //   onChange={getUserData}
                                    placeholder="Phone Number"
                                    required
                                  />
                                </div>
                              </fieldset>
                            </Col>
                            {/* <Col lg="12">
                            <fieldset>
                              <div className="input-group py-4">
                                <input
                                  type={passwordType}
                                  onChange={handlePasswordChange}
                                  value={passwordInput}
                                  name="password"
                                  class="form-control py-2"
                                  placeholder="Password"
                                  required
                                />
                                <Button onClick={togglePassword}>
                                  {passwordType === "password" ? (
                                    <BsEyeFill />
                                  ) : (
                                    <BsEyeFill />
                                  )}
                                </Button>
                              </div>
                            </fieldset>
                          </Col> */}
                            <Col lg="12">
                              <fieldset>
                                {/* <label style={{ width: "100%" }}>First name</label> */}
                                <input
                                  style={{ width: "100%" }}
                                  className="form-control"
                                  type={"Email"}
                                  name="email"
                                  // onChange={(e) =>
                                  //   setFreelancerSignUpData({
                                  //     ...freelancerSignupData,
                                  //     email: e.target.value,
                                  //   })
                                  // }
                                  //   value={user.name}
                                  //   onChange={getUserData}
                                  placeholder="Enter Your Email Address"
                                  required
                                />
                              </fieldset>
                            </Col>
                            <Col lg="12">
                              <fieldset>
                                {/* <label style={{ width: "100%" }}>First name</label> */}
                                <input
                                  style={{ width: "100%" }}
                                  className="form-control"
                                  type={"password"}
                                  // onChange={(e) =>
                                  //   setFreelancerSignUpData({
                                  //     ...freelancerSignupData,
                                  //     confirmPassword: e.target.value,
                                  //   })
                                  // }
                                  //   value={user.name}
                                  //   onChange={getUserData}
                                  placeholder="Password"
                                  required
                                />
                              </fieldset>
                            </Col>
                          </Row>
                          <h3
                            style={{
                              fontSize: "15px",
                              color: "black",
                              //   display: "flex",
                              textAlign: "left",
                            }}
                            className="py-2"
                          >
                            <span
                              style={{
                                display: "inline-flex",
                                color: "#7A7979",
                              }}
                              className="py-2"
                            >
                              <FormCheck color="blue" />
                              &#160;&#160;By clicking Submit, you agree to all
                              our terms & general agreement
                            </span>
                          </h3>
                          <Col lg="12" className="webkit">
                            <div className="py-3">
                              <Button
                                className="btnMain w-2/3"
                                //   onClick={freelancerFunc}
                              >
                                SIGN UP
                              </Button>
                            </div>
                            <div className="p-3 webkit">
                              <h2
                                style={{ color: "#6D6E71", fontSize: "16px" }}
                              >
                                Existing User ?{" "}
                                <Link to="/Login">
                                  <span style={{ color: "#1E75BB" }}>
                                    Log in
                                  </span>
                                </Link>
                              </h2>
                            </div>
                          </Col>
                        </div>
                      </TabPanel>

                      <TabPanel>
                        <div className="p-3">
                          <Row>
                            <Col lg="12">
                              <fieldset>
                                {/* <label style={{ width: "100%" }}>First name</label> */}
                                <input
                                  style={{ width: "100%" }}
                                  className="form-control"
                                  type={"text"}
                                  name="Fname"
                                  // onChange={(e) =>
                                  //   setFreelancerSignUpData({
                                  //     ...freelancerSignupData,
                                  //     firstName: e.target.value,
                                  //   })
                                  // }
                                  //   value={user.name}
                                  //   onChange={getUserData}
                                  placeholder="Company Name"
                                  required
                                />
                              </fieldset>
                            </Col>
                            <Col lg="12">
                              <fieldset>
                                {/* <label style={{ width: "100%" }}>First name</label> */}
                                <input
                                  style={{ width: "100%" }}
                                  className="form-control"
                                  type={"text"}
                                  name="Lname"
                                  // onChange={(e) =>
                                  //   setFreelancerSignUpData({
                                  //     ...freelancerSignupData,
                                  //     lastName: e.target.value,
                                  //   })
                                  // }
                                  //   value={user.name}
                                  //   onChange={getUserData}
                                  placeholder="Select Country"
                                  required
                                />
                              </fieldset>
                            </Col>
                            <Col lg="12">
                              <fieldset>
                                {/* <label style={{ width: "100%" }}>First name</label> */}
                                <input
                                  style={{ width: "100%" }}
                                  className="form-control"
                                  type={"Email"}
                                  name="email"
                                  // onChange={(e) =>
                                  //   setFreelancerSignUpData({
                                  //     ...freelancerSignupData,
                                  //     email: e.target.value,
                                  //   })
                                  // }
                                  //   value={user.name}
                                  //   onChange={getUserData}
                                  placeholder="Enter Full Name"
                                  required
                                />
                              </fieldset>
                            </Col>
                            <Col lg="12">
                              <fieldset>
                                <div className="input-group">
                                  {/* <label style={{ width: "100%" }}>First name</label> */}
                                  <input
                                    style={{ width: "100%" }}
                                    className="form-control"
                                    type={"number"}
                                    name="password"
                                    // onChange={(e) =>
                                    //   setFreelancerSignUpData({
                                    //     ...freelancerSignupData,
                                    //     password: e.target.value,
                                    //   })
                                    // }
                                    //   value={user.name}
                                    //   onChange={getUserData}
                                    placeholder="Phone Number"
                                    required
                                  />
                                </div>
                              </fieldset>
                            </Col>
                            {/* <Col lg="12">
                            <fieldset>
                              <div className="input-group py-4">
                                <input
                                  type={passwordType}
                                  onChange={handlePasswordChange}
                                  value={passwordInput}
                                  name="password"
                                  class="form-control py-2"
                                  placeholder="Password"
                                  required
                                />
                                <Button onClick={togglePassword}>
                                  {passwordType === "password" ? (
                                    <BsEyeFill />
                                  ) : (
                                    <BsEyeFill />
                                  )}
                                </Button>
                              </div>
                            </fieldset>
                          </Col> */}
                            <Col lg="12">
                              <fieldset>
                                {/* <label style={{ width: "100%" }}>First name</label> */}
                                <input
                                  style={{ width: "100%" }}
                                  className="form-control"
                                  type={"password"}
                                  // onChange={(e) =>
                                  //   setFreelancerSignUpData({
                                  //     ...freelancerSignupData,
                                  //     confirmPassword: e.target.value,
                                  //   })
                                  // }
                                  //   value={user.name}
                                  //   onChange={getUserData}
                                  placeholder="Password"
                                  required
                                />
                              </fieldset>
                            </Col>
                          </Row>
                          <h3
                            style={{
                              fontSize: "15px",
                              color: "black",
                              //   display: "flex",
                              textAlign: "left",
                            }}
                            className="py-2"
                          >
                            <span
                              style={{
                                display: "inline-flex",
                                color: "#7A7979",
                              }}
                              className="py-2"
                            >
                              <FormCheck color="blue" />
                              &#160;&#160;By clicking Submit, you agree to all
                              our terms & general agreement
                            </span>
                          </h3>
                          <Col lg="12" className="webkit">
                            <div className="py-3">
                              <Button
                                className="btnMain w-2/3"
                                //   onClick={freelancerFunc}
                              >
                                SIGN UP
                              </Button>
                            </div>
                            <div className="p-3 webkit">
                              <h2
                                style={{ color: "#6D6E71", fontSize: "16px" }}
                              >
                                New User ?{" "}
                                <Link to="/Login">
                                  <span style={{ color: "#1E75BB" }}>
                                    Log in
                                  </span>
                                </Link>
                              </h2>
                            </div>
                          </Col>
                        </div>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="6">
            <div className="p-3 webkit">
              <Image className="w-9/12" src={require("../Assets/login.png")} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="align-items-center">
          <Col lg="12">
            <div className="p-3 py-4">
              <h2 className="text-2xl" style={{ color: "#3BB44A" }}>
                Exporters Directory Business Listing Terms
              </h2>
              <p className="py-2" style={{ color: "#606060" }}>
                Textilepages.com request you to read its business listing terms
                carefully before accessing, using or list your textile or
                textile industry related business to our website. By accessing
                or using any listing form of the site, you agree to be bound by
                these business listing terms. If you do not agree to our listing
                terms, then you should not use any of our free or paid listing
                services .
              </p>
              <p className="pt-4" style={{ color: "#606060" }}>
                Any new features or tools also be subject to the business
                listing terms. We reserve the right to update, change or replace
                any part of these terms by posting updates and/or changes to our
                website. It is your responsibility to check this page
                periodically for changes. Your continued use of or access to the
                website following the posting of any changes constitutes
                acceptance of those changes .
              </p>
            </div>
            <div className="p-3 py-4">
              <h2 className="text-2xl" style={{ color: "#3BB44A" }}>
                Business Listing - Service Overview
              </h2>
              <p className="py-2" style={{ color: "#606060" }}>
                Textilepages helps bridging the gap between textile buyer and
                seller, retailers and wholesalers by providing best product or
                service advertising and communication services which include
                textile marketing, advertising and e-marketing, textile
                emarketing and textile product promotion and branding, textile
                web promotions, garment apparel product online advertising or
                internet marketing, apparels product marketing and branding,
                textile business promotions, textile industry related business
                marketing, textile product website marketing, global textile
                marketing, textile industry related business online e-marketing
                and textile ads, premium marketing service for textile industry
                related businesses, e-commerce textile product marketing and
                advertising, textile advertiser, textile marketer or textile
                marketing company services etc. Textile businesses can join free
                to this apparel, fashion and retail industry portal and get all
                b2b benefits like product listing, b2b buy sell offer listing,
                b2b textile directory listing, global b2b textile marketplace
                listing, textile company certificate listing, promotional
                document, textile picture or video listing etc.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
