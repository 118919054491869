import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";

export const BusinessPotential = () => {
  return (
    <>
      <Container>
        <Row className="py-3 webkit">
          <div className="py-5">
            <h2 className="text-2xl font-semibold" style={{ color: "#1E75BB" }}>
              Unlock Your Business Potential
            </h2>
            <p className="text-lg" style={{ color: "#414042" }}>
              Check what’s popular make your project look trendy and
              professional.
            </p>
          </div>
        </Row>
      </Container>
      <Container
        fluid
        className=" py-4"
        style={{ backgroundColor: "#00aeef4d" }}
      >
        <Container>
          <Row
            xs={2}
            md={4}
            lg={6}
            className="align-items-center webkit justify-center"
          >
            <Col>
              <div>
                <Image src={require("../Assets/Potential1.png")} />
              </div>
            </Col>
            <Col>
              <div>
                <Image src={require("../Assets/Potential2.png")} />
              </div>
            </Col>
            <Col>
              <div>
                <Image src={require("../Assets/Potential3.png")} />
              </div>
            </Col>
            <Col>
              <div>
                <Image src={require("../Assets/Potential4.png")} />
              </div>
            </Col>
            <Col>
              <div>
                <Image src={require("../Assets/Potential5.png")} />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
