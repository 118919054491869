import React from "react";
import { Container, Row } from "react-bootstrap";

export const UpcomingExhibitions = () => {
  return (
    <Container fluid>
      <Container>
        <Row className="py-3 webkit">
          <div className="py-5">
            <h2 className="text-2xl font-semibold" style={{ color: "#1E75BB" }}>
              Upcoming Exhibitions
            </h2>
            <p className="text-lg" style={{ color: "#414042" }}>
              Check what’s popular make your project look trendy and
              professional.
            </p>
          </div>
        </Row>
      </Container>
    </Container>
  );
};
