import React from "react";
import { Login } from "../LoginAndSignup/Login";
import { ConnectTextile } from "../HomeComponents/ConnectTextile";

export const LoginMain = () => {
  return (
    <>
      <Login />
      <ConnectTextile />
    </>
  );
};
