import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export const MainRecuirements = () => {
  const [creditCard, setCreditCard] = useState();
  const [productImage, setProductImage] = useState();
  console.log("===>", creditCard);
  const [bankTransfer, setBankTransfer] = useState(false);
  const [mail, setMail] = useState(false);
  const [flag, setFlag] = useState(false);
  return (
    <Container>
      <Row className="py-4 justify-center">
        <Col lg="10">
          <div className="bg-white rounded-xl">
            <div className="py-3 px-3">
              <h2 className="text-3xl font-bold" style={{ color: "#3BB44A" }}>
                Add Buy Offer
              </h2>
              <p className="text-sm text-black">
                Get quotes from the top suppliers just by posting your
                requirement below;
              </p>
            </div>
            <hr />
            <div className="p-3">
              <fieldset>
                <Form.Group className="mb-3">
                  <Row className="align-items-center">
                    <Col lg="3">
                      <Form.Label style={{ color: "#6D6E71" }}>
                        Product Required :
                      </Form.Label>
                    </Col>
                    <Col lg="9">
                      <Form.Control
                        placeholder="Enter product name"
                        className=" mt-2"
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </fieldset>
              <fieldset>
                <Form.Group className="mb-3">
                  <Row className="align-items-center">
                    <Col lg="3">
                      <Form.Label style={{ color: "#6D6E71" }}>
                        Product Category :
                      </Form.Label>
                    </Col>
                    <Col lg="9">
                      <select value="odho" className="form-control mt-2">
                        <option>Select Product Category</option>
                        <option>2</option>
                        <option>1</option>
                        <option>2</option>
                      </select>
                    </Col>
                  </Row>
                </Form.Group>
              </fieldset>
              <fieldset>
                <Form.Group className="mb-3">
                  <Row className="align-items-center">
                    <Col lg="3">
                      <Form.Label style={{ color: "#6D6E71" }}>
                        Requirement Details :
                      </Form.Label>
                    </Col>
                    <Col lg="9">
                      <textarea
                        placeholder="Describe your buying requirement including specification, size, etc"
                        cols="3"
                        className="form-control mt-2"
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </fieldset>
              <fieldset>
                <Form.Group className="mb-3">
                  <Row className="align-items-center">
                    <Col lg="3">
                      <Form.Label style={{ color: "#6D6E71" }}>
                        Buy Quantity :
                      </Form.Label>
                    </Col>
                    <Col lg="9">
                      <Row>
                        <Col lg="6">
                          <Form.Control
                            placeholder="Buy quantity"
                            className=" mt-2"
                          />
                        </Col>
                        <Col lg="6">
                          <select value="odho" className="form-control mt-2">
                            <option>Select unit</option>
                            <option>2</option>
                            <option>1</option>
                            <option>2</option>
                          </select>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>
              </fieldset>
              <fieldset>
                <Form.Group className="mb-3">
                  <Row className="align-items-center">
                    <Col lg="3">
                      <Form.Label style={{ color: "#6D6E71" }}>
                        Packaging Terms :
                      </Form.Label>
                    </Col>
                    <Col lg="9">
                      <Form.Control
                        placeholder="ashirdws@gmail.com"
                        className=" mt-2"
                        disabled
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </fieldset>
              <div className="p-3">
                <Row>
                  <fieldset>
                    <div className="form-group row product-img">
                      <Col lg="3">
                        <Form.Label style={{ color: "#6D6E71" }}>
                          Have Product Images?
                        </Form.Label>
                      </Col>
                      <div className="col-lg-9">
                        <div className="flex gap-3 new">
                          <label className="cute-label mr-2">
                            <input
                              value={productImage}
                              onChange={() => setProductImage("creditCard")}
                              type="radio"
                              className="form-check-input"
                              name="radios"
                              id="radio1"
                            />
                            Yes
                            <span className="cute-radio"></span>
                          </label>
                          <label className="cute-label">
                            <input
                              onChange={() => setProductImage("bankTransfer")}
                              value={productImage}
                              type="radio"
                              className="form-check-input "
                              name="radios"
                              id="radio2"
                            />
                            No
                            <span className="cute-radio"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <Col className="pt-4">
                    {productImage === "creditCard" ? (
                      <Col>
                        <div className="p-3">
                          <fieldset>
                            <Form.Group>
                              <Row className="align-items-center">
                                <Col lg="3">
                                  <Form.Label style={{ color: "#6D6E71" }}>
                                    Upload Image
                                  </Form.Label>
                                </Col>
                                <Col lg="9">
                                  <Form.Group controlId="formFile">
                                    <Form.Control
                                      style={{ width: "50%" }}
                                      type="file"
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Form.Group>
                          </fieldset>
                        </div>
                      </Col>
                    ) : null}
                    {productImage === "bankTransfer" ? (
                      <Col>
                        <div></div>
                      </Col>
                    ) : null}
                  </Col>
                </Row>
              </div>
            </div>
            <hr />
            <div className="p-3">
              <Row className="align-items-center">
                <Col className="text-sm">
                  <input
                    value={creditCard}
                    onChange={() => setCreditCard("creditCard")}
                    type="radio"
                    className="form-check-input"
                    name="bsradio"
                    id="radio1"
                  />
                  <label for="radio1" class="form-check-label pl-2">
                    Existing Member
                  </label>
                </Col>
                <Col className="text-sm">
                  <input
                    onChange={() => setCreditCard("bankTransfer")}
                    value={creditCard}
                    type="radio"
                    className="form-check-input "
                    name="bsradio"
                    id="radio2"
                  />
                  <label for="radio2" class="form-check-label pl-2">
                    New Member
                  </label>
                </Col>
              </Row>
            </div>

            <div className="p-3">
              <Row>
                <Col className="pt-4">
                  {creditCard === "creditCard" ? (
                    <Col>
                      <div className="p-3">
                        <fieldset>
                          <Form.Group className="mb-3">
                            <Row className="align-items-center">
                              <Col lg="3">
                                <Form.Label style={{ color: "#6D6E71" }}>
                                  Email Address :
                                </Form.Label>
                              </Col>
                              <Col lg="9">
                                <Form.Control
                                  placeholder="Enter email address"
                                  className=" mt-2"
                                />
                              </Col>
                            </Row>
                          </Form.Group>
                        </fieldset>
                        <fieldset>
                          <Form.Group className="mb-3">
                            <Row className="align-items-center">
                              <Col lg="3">
                                <Form.Label style={{ color: "#6D6E71" }}>
                                  Password :
                                </Form.Label>
                              </Col>
                              <Col lg="9">
                                <Form.Control
                                  placeholder="Enter password"
                                  className=" mt-2"
                                />
                              </Col>
                            </Row>
                          </Form.Group>
                        </fieldset>
                      </div>
                    </Col>
                  ) : null}
                  {creditCard === "bankTransfer" ? (
                    <Col>
                      <div className="p-3">
                        <fieldset>
                          <Form.Group className="mb-3">
                            <Row className="align-items-center">
                              <Col lg="3">
                                <Form.Label style={{ color: "#6D6E71" }}>
                                  Company Name :
                                </Form.Label>
                              </Col>
                              <Col lg="9">
                                <Form.Control
                                  placeholder="Enter company name"
                                  className=" mt-2"
                                />
                              </Col>
                            </Row>
                          </Form.Group>
                        </fieldset>
                        <fieldset>
                          <Form.Group className="mb-3">
                            <Row className="align-items-center">
                              <Col lg="3">
                                <Form.Label style={{ color: "#6D6E71" }}>
                                  Country :
                                </Form.Label>
                              </Col>
                              <Col lg="9">
                                <select
                                  value="odho"
                                  className="form-control mt-2"
                                >
                                  <option>Select Country</option>
                                  <option>2</option>
                                  <option>1</option>
                                  <option>2</option>
                                </select>
                              </Col>
                            </Row>
                          </Form.Group>
                        </fieldset>
                        <fieldset>
                          <Form.Group className="mb-3">
                            <Row className="align-items-center">
                              <Col lg="3">
                                <Form.Label style={{ color: "#6D6E71" }}>
                                  Contact Person :
                                </Form.Label>
                              </Col>
                              <Col lg="9">
                                <Form.Control
                                  placeholder="Enter your name"
                                  className=" mt-2"
                                />
                              </Col>
                            </Row>
                          </Form.Group>
                        </fieldset>
                        <fieldset>
                          <Form.Group className="mb-3">
                            <Row className="align-items-center">
                              <Col lg="3">
                                <Form.Label style={{ color: "#6D6E71" }}>
                                  Email :
                                </Form.Label>
                              </Col>
                              <Col lg="9">
                                <Form.Control
                                  placeholder="Enter email address"
                                  className=" mt-2"
                                />
                              </Col>
                            </Row>
                          </Form.Group>
                        </fieldset>
                        <fieldset>
                          <Form.Group className="mb-3">
                            <Row className="align-items-center">
                              <Col lg="3">
                                <Form.Label style={{ color: "#6D6E71" }}>
                                  Phone :
                                </Form.Label>
                              </Col>
                              <Col lg="9">
                                <Form.Control
                                  placeholder="Phone number"
                                  className=" mt-2"
                                />
                              </Col>
                            </Row>
                          </Form.Group>
                        </fieldset>
                        <fieldset>
                          <Form.Group className="mb-3">
                            <Row className="align-items-center">
                              <Col lg="3">
                                <Form.Label style={{ color: "#6D6E71" }}>
                                  Password :
                                </Form.Label>
                              </Col>
                              <Col lg="9">
                                <Form.Control
                                  placeholder="Enter password"
                                  className=" mt-2"
                                />
                              </Col>
                            </Row>
                          </Form.Group>
                        </fieldset>
                      </div>
                    </Col>
                  ) : null}
                </Col>
              </Row>
            </div>
            <div className="p-3 webkit">
              <input
                type="checkbox"
                class="form-check-input"
                id="customCheck6"
                Checked=""
              />{" "}
              By clicking Submit, you agree to our service terms & general
              agreement
            </div>
            <Row className="py-4">
              <Col className="webkit">
                <din className="p-3">
                  <Button className="btnMain w-6/12">Submit Buy Offer</Button>
                </din>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
