import React from "react";
import { AllCategories } from "../MainCategoryComponents/AllCategories";
import { CategorySearchbar } from "../MainCategoryComponents/CategorySearchbar";
import { ConnectTextile } from "../HomeComponents/ConnectTextile";

export const CategoryMain = () => {
  return (
    <>
      <CategorySearchbar />
      <AllCategories />
      <ConnectTextile />
    </>
  );
};
