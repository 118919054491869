import React from "react";
import { BannerExporter } from "../ExportersComponents/BannerExporter";
import { Main } from "../ExportersComponents/Main";
import { MainBanner } from "../ExportersComponents/MainBanner";
import { ConnectTextile } from "../HomeComponents/ConnectTextile";

export const Exporters = () => {
  return (
    <>
      <MainBanner />
      <Main />
      <BannerExporter />
      <ConnectTextile />
    </>
  );
};
