import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";

export const ProductsGallery2 = () => {
  return (
    <div className="mt-3 bg-white rounded-lg py-3">
      <div className="p-3">
        <h2 className="text-2xl" style={{ color: "#414042" }}>
          Product Gallery 2
        </h2>
      </div>
      <hr />
      <Container>
        <Row xs={2} md={4} lg={8}>
          <Col>
            <div className="p-3">
              <Image src={require("../Assets/ProductGallery2.png")} />
            </div>
          </Col>
          <Col>
            <div className="p-3">
              <Image src={require("../Assets/ProductGallery2.png")} />
            </div>
          </Col>
          <Col>
            <div className="p-3">
              <Image src={require("../Assets/ProductGallery2.png")} />
            </div>
          </Col>
          <Col>
            {" "}
            <div className="p-3">
              <Image src={require("../Assets/ProductGallery2.png")} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
