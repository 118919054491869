import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";

export const ListOfProduct = () => {
  return (
    <div className="mt-3 bg-white rounded-lg py-3">
      <div className="p-3">
        <h2 className="text-2xl" style={{ color: "#414042" }}>
          List of Product & Services:
        </h2>
      </div>
      <hr />
      <Container>
        <Row xs={2} md={4} lg={8}>
          <Col>
            <div className="p-3">
              <ul className="list-disc ddes">
                <li>Foosd Product</li>
                <li>Foosd Product</li>
                <li>Foosd Product</li>
                <li>Foosd Product</li>
                <li>Foosd Product</li>
              </ul>
            </div>
          </Col>
          <Col>
            <div className="p-3">
              <ul className="list-disc ddes">
                <li>Foosd Product</li>
                <li>Foosd Product</li>
                <li>Foosd Product</li>
                <li>Foosd Product</li>
                <li>Foosd Product</li>
              </ul>
            </div>
          </Col>
          <Col>
            <div className="p-3">
              <ul className="list-disc ddes">
                <li>Foosd Product</li>
                <li>Foosd Product</li>
                <li>Foosd Product</li>
                <li>Foosd Product</li>
                <li>Foosd Product</li>
              </ul>
            </div>
          </Col>
          <Col>
            <div className="p-3">
              <ul className="list-disc ddes">
                <li>Foosd Product</li>
                <li>Foosd Product</li>
                <li>Foosd Product</li>
                <li>Foosd Product</li>
                <li>Foosd Product</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
