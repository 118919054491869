import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";

export const TrendingProducts = () => {
  return (
    <Container fluid>
      <Container>
        <Row className="py-3 webkit">
          <div className="py-5">
            <h2 className="text-2xl font-semibold" style={{ color: "#1E75BB" }}>
              Explore Trending Products
            </h2>
            <p className="text-lg" style={{ color: "#414042" }}>
              Check what’s popular make your project look trendy and
              professional.
            </p>
          </div>
        </Row>
        <Row xs={2} md={4} lg={8} className="py-3">
          <Col lg="3" style={{ background: "rgb(30, 117, 187)" }}>
            <div className="px-2 webkit MainBox">
              <Image
                style={{ objectFit: "cover", width: "80%" }}
                src={require("../Assets/trendImg.png")}
              />
            </div>
          </Col>
          <Col
            lg="3"
            className="bg-white"
            style={{
              borderTop: "1px solid black",
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
              borderBottom: "1px solid",
            }}
          >
            {/* <div style={{ display: "inline-grid" }}> */}
            <Row>
              <Col className="py-3 px-2 webkit mainB1">
                <Image
                  style={{ objectFit: "contain", width: "20%" }}
                  src={require("../Assets/trendImg1.png")}
                />
                <h2 className="text-sm pt-2">Car Tyre</h2>
              </Col>
              <hr className="hr" />
              <Col className="py-3 px-2 webkit mainB1">
                <Image
                  style={{ objectFit: "cover", width: "40%" }}
                  src={require("../Assets/trendImg2.png")}
                />
                <h2 className="text-sm">Shipping Container</h2>
              </Col>
            </Row>
            {/* </div> */}
          </Col>
          <Col lg="3" className="bg-white brde">
            <Row>
              <Col className="py-3 px-2 webkit mainB1">
                <Image
                  style={{ objectFit: "cover", width: "40%" }}
                  src={require("../Assets/trendImg3.png")}
                />
                <h2 className="text-sm pt-2">Farm Tractor </h2>
              </Col>
              <hr className="hr" />
              <Col className="py-3 px-2 webkit mainB1">
                <Image
                  style={{ objectFit: "cover", width: "40%" }}
                  src={require("../Assets/trendImg4.png")}
                />
                <h2 className="text-sm">Backpack Bag</h2>
              </Col>
            </Row>
          </Col>
          <Col
            lg="3"
            className="bg-white"
            style={{
              borderTop: "1px solid black",
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
              borderBottom: "1px solid",
            }}
          >
            <Row>
              <Col className="py-3 px-2 webkit mainB1">
                <Image
                  style={{ objectFit: "cover", width: "20%" }}
                  src={require("../Assets/trendImg5.png")}
                />
                <h2 className="text-sm pt-2">Table Couch Bed Chair</h2>
              </Col>
              <hr className="hr" />
              <Col className="py-3 px-2 webkit mainB1">
                <Image
                  style={{ objectFit: "cover", width: "40%" }}
                  src={require("../Assets/trendImg6.png")}
                />
                <h2 className="text-sm">LED Lights</h2>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
