import React from "react";

export const SafeFood = () => {
  return (
    <div className="mt-3 bg-white rounded-lg py-2">
      <div className="p-3">
        <h2 className="text-2xl" style={{ color: "#414042" }}>
          SafeFood Description
        </h2>
      </div>
      <hr />
      <div className="p-3">
        <p className="text-lg" style={{ color: "#6D6E71" }}>
          Pakistani dishes are known for having aromatic and sometimes spicy
          flavours. Some dishes contain liberal amounts of oil, which contribute
          to a richer, fuller mouthfeel and flavour. Brown cardamom, green
          cardamom, cinnamon, cloves, nutmeg, mace, and black pepper are the
          most commonly used spices in the making of a wide variety of dishes
          throughout Pakistan. Cumin seeds, chili powder, turmeric, and bay
          leaves are also very popular. In the Punjab province, spice blends are
          characterized by their use of coriander powder. Garam masala (a
          mixture of aromatic spices) is a popular blend of spices used in
          several Pakistani dishes.
        </p>
      </div>
    </div>
  );
};
