import React from "react";
import { Container } from "react-bootstrap";
import { ExportersCatMain } from "../ExportersMainComponents/ExportersCatMain";
import { MainExporter } from "../ExportersMainComponents/MainExporter";
import { ConnectTextile } from "../HomeComponents/ConnectTextile";

export const ExportersMain = () => {
  return (
    <>
      <MainExporter />
      <ExportersCatMain />
      <ConnectTextile />
    </>
  );
};
