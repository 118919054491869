import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button, Image, FormCheck } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { BannerExporter } from "../ExportersComponents/BannerExporter";
import { FactoryTour } from "../ExportersComponents/FactoryTour";
import { LegalNoticed } from "../ExportersComponents/LegalNoticed";
import { ProductCategory } from "./ProductCategory";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";

export const Sidemenu = () => {
  const [productCategory, setProductCategory] = useState("");

  const Component = () => {
    if (productCategory === "Agribusiness") {
      return <ProductCategory />;
    } else if (productCategory === "blog") {
      return <LegalNoticed />;
    } else if (productCategory === "FAQ") {
      return <BannerExporter />;
    } else if (productCategory === "GoldenTouch") {
      return <FactoryTour />;
    } else {
      return <ProductCategory />;
    }
  };
  const [toggle, setToggle] = useState(true);
  return (
    // <Menu>
    //   <h2 className="text-center">Agro & Agriculture</h2>
    //   <a className="menu-item" href="/">
    //     Home
    //   </a>
    //   <a className="menu-item" href="/laravel">
    //     Laravel
    //   </a>

    //   <a className="menu-item" href="/angular">
    //     Angular
    //   </a>

    //   <a className="menu-item" href="/react">
    //     React
    //   </a>

    //   <a className="menu-item" href="/vue">
    //     Vue
    //   </a>

    //   <a className="menu-item" href="/node">
    //     Node
    //   </a>
    // </Menu>
    <Container>
      <Tabs>
        <Col lg="3" className="clacla">
          <div>
            <div
              style={{ backgroundColor: "#414042" }}
              className="p-2 webkit flex align-items-center"
            >
              <ToggleButton
                className="text-white border-solid border-white mx-2"
                value="left"
                aria-label="left aligned"
              >
                <FormatAlignLeftIcon onClick={() => setToggle(!toggle)} />
              </ToggleButton>
              <h2 className="text-2xl text-white">Agro & Agriculture</h2>
            </div>
            {toggle && (
              <TabList>
                <Tab onClick={() => setProductCategory("Agribusiness")}>
                  <h5>Agribusiness</h5>
                </Tab>
                <Tab onClick={() => setProductCategory("blog")}>
                  <h5>Agricultural & Gardening Tools</h5>
                </Tab>
                <Tab onClick={() => setProductCategory("FAQ")}>
                  <h5>Agriculture Product Stocks</h5>
                </Tab>
                <Tab onClick={() => setProductCategory("GoldenTouch")}>
                  <h5>Agriculture Products Processing</h5>
                </Tab>
                <Tab onClick={() => setProductCategory("Agribusiness")}>
                  <h5>Agro Chemicals</h5>
                </Tab>
                <Tab onClick={() => setProductCategory("blog")}>
                  <h5>Animal Extract</h5>
                </Tab>
                <Tab onClick={() => setProductCategory("FAQ")}>
                  <h5>Animal Fodders</h5>
                </Tab>
                <Tab onClick={() => setProductCategory("GoldenTouch")}>
                  <h5>Animal Husbandry</h5>
                </Tab>
                <Tab onClick={() => setProductCategory("blog")}>
                  <h5>Aquaculture Equipment </h5>
                </Tab>
                <Tab onClick={() => setProductCategory("FAQ")}>
                  <h5>Beans & Grains</h5>
                </Tab>
                <Tab onClick={() => setProductCategory("GoldenTouch")}>
                  <h5>Cereal</h5>
                </Tab>
                <Tab onClick={() => setProductCategory("Disclaimer")}>
                  <h5>Charcoal</h5>
                </Tab>
                <Tab onClick={() => setProductCategory("MyFavorite")}>
                  <h5>Cigarette & Tobacco</h5>
                </Tab>
                <Tab onClick={() => setProductCategory("ContactUs")}>
                  <h5>Cotton</h5>
                </Tab>
                <Tab onClick={() => setProductCategory("Disclaimer")}>
                  <h5>Crop & Commodities</h5>
                </Tab>
                <Tab onClick={() => setProductCategory("MyFavorite")}>
                  <h5>Farming Machinery</h5>
                </Tab>
                <Tab>
                  <h5>Fishery Machinery & Parts</h5>
                </Tab>

                <Tab onClick={() => setProductCategory("Agribusiness")}>
                  <h5>Flower & Plant</h5>
                </Tab>
                <Tab onClick={() => setProductCategory("blog")}>
                  <h5>Fodder & Feed Additives</h5>
                </Tab>
                <Tab onClick={() => setProductCategory("FAQ")}>
                  <h5>Forest Machinery & Parts</h5>
                </Tab>
                <Tab onClick={() => setProductCategory("GoldenTouch")}>
                  <h5>Fruit</h5>
                </Tab>
                <Tab onClick={() => setProductCategory("Agribusiness")}>
                  <h5>Herbs</h5>
                </Tab>
                <Tab onClick={() => setProductCategory("blog")}>
                  <h5>Livestock Products</h5>
                </Tab>
                <Tab onClick={() => setProductCategory("FAQ")}>
                  <h5>Nuts & Kernels</h5>
                </Tab>
                <Tab onClick={() => setProductCategory("GoldenTouch")}>
                  <h5>Plant & Animal Oil</h5>
                </Tab>
                <Tab onClick={() => setProductCategory("blog")}>
                  <h5>Plant Extract </h5>
                </Tab>
                <Tab onClick={() => setProductCategory("FAQ")}>
                  <h5>Plant Seed</h5>
                </Tab>
                <Tab onClick={() => setProductCategory("GoldenTouch")}>
                  <h5>Rice & Wheat</h5>
                </Tab>
                <Tab onClick={() => setProductCategory("Disclaimer")}>
                  <h5>Vegetable</h5>
                </Tab>
              </TabList>
            )}
          </div>
        </Col>
        {/* <Col lg="3" className="clacla">
          <div style={{ backgroundColor: "#414042" }} className="p-2 webkit ">
            <h2 className="text-2xl text-white">Agro & Agriculture</h2>
          </div>
          <TabList>
            <Tab onClick={() => setProductCategory("Agribusiness")}>
              <h5>Agribusiness</h5>
            </Tab>
            <Tab onClick={() => setProductCategory("blog")}>
              <h5>BLOG</h5>
            </Tab>
            <Tab onClick={() => setProductCategory("FAQ")}>
              <h5>FAQ</h5>
            </Tab>
            <Tab onClick={() => setProductCategory("GoldenTouch")}>
              <h5>GOLDEN TOUCH</h5>
            </Tab>
           
          </TabList>
        </Col> */}
        <Col lg="9">
          <view>
            <Component />
          </view>
        </Col>
      </Tabs>
    </Container>
  );
};
