import React from "react";
import { ConnectTextile } from "../HomeComponents/ConnectTextile";
import { Main } from "../ProductsComponents/Main";
import { MainProduct } from "../ProductsComponents/MainProduct";
export const Products = () => {
  return (
    <>
      <Main />
      <MainProduct />
      <ConnectTextile />
    </>
  );
};
