import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";

export const ChanelPartner = () => {
  return (
    <>
      <Container>
        <Row className="py-3 webkit">
          <div className="py-5">
            <h2 className="text-2xl font-semibold" style={{ color: "#1E75BB" }}>
              Our Channel Partners
            </h2>
            <p className="text-lg" style={{ color: "#414042" }}>
              Check what’s popular make your project look trendy and
              professional.
            </p>
          </div>
        </Row>
      </Container>
      <Container fluid className=" py-4" style={{ backgroundColor: "white" }}>
        <Container>
          <Row xs={2} md={4} lg={6} className="align-items-center webkit ghgh">
            <Col>
              <div className="p-4">
                <Image src={require("../Assets/Partner1.png")} />
              </div>
            </Col>
            <Col>
              <div className="p-4">
                <Image src={require("../Assets/Partner2.png")} />
              </div>
            </Col>

            <Col>
              <div className="p-4">
                <Image src={require("../Assets/Partner3.png")} />
              </div>
            </Col>
            <Col>
              <div className="p-4">
                <Image src={require("../Assets/Partner4.png")} />
              </div>
            </Col>

            <Col>
              <div className="p-4">
                <Image src={require("../Assets/Partner5.png")} />
              </div>
            </Col>
            <Col>
              <div className="p-4">
                <Image src={require("../Assets/Partner6.png")} />
              </div>
            </Col>
          </Row>
          <Row xs={2} md={4} lg={6} className="align-items-center webkit ghgh">
            <Col>
              <div className="p-4">
                <Image src={require("../Assets/Partner1.png")} />
              </div>
            </Col>
            <Col>
              <div className="p-4">
                <Image src={require("../Assets/Partner2.png")} />
              </div>
            </Col>

            <Col>
              <div className="p-4">
                <Image src={require("../Assets/Partner3.png")} />
              </div>
            </Col>
            <Col>
              <div className="p-4">
                <Image src={require("../Assets/Partner4.png")} />
              </div>
            </Col>

            <Col>
              <div className="p-4">
                <Image src={require("../Assets/Partner5.png")} />
              </div>
            </Col>
            <Col>
              <div className="p-4">
                <Image src={require("../Assets/Partner6.png")} />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
