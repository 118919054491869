import logo from "./logo.svg";
import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Home } from "./Components/Screens/Home";
import { Topnav } from "./Components/NavbarComponent/Topnav";
import { Footer } from "./Components/FooterComponents/Footer";
import { LoginMain } from "./Components/Screens/LoginMain";
import { SignupMain } from "./Components/Screens/SignupMain";
import { Products } from "./Components/Screens/Products";
import { Exporters } from "./Components/Screens/Exporters";
import { ExportersMain } from "./Components/Screens/ExportersMain";
import { CategoryMain } from "./Components/Screens/CategoryMain";
import { Category } from "./Components/Screens/Category";
import { BuyRecuirements } from "./Components/Screens/BuyRecuirements";

function App() {
  return (
    <>
      <Topnav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Products" element={<Products />} />
        <Route path="/ExportersCategory" element={<Exporters />} />
        <Route path="/Exporters" element={<ExportersMain />} />
        <Route path="/MainCategory" element={<CategoryMain />} />
        <Route path="/Category" element={<Category />} />
        <Route path="/BuyNewRecuirements" element={<BuyRecuirements />} />
        <Route path="/Login" element={<LoginMain />} />
        <Route path="/Signup" element={<SignupMain />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
