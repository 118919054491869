import React from "react";

import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import { Row, Image } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { BsFacebook, BsInstagram, BsLinkedin, BsTwitter } from "react-icons/bs";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <MDBFooter
      className="text-center text-lg-start text-muted"
      style={{ background: "#1E75BB" }}
    >
      <section className="">
        <MDBContainer className="text-left text-md-start pt-5 text-white">
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
              <Image
                style={{ width: "70%" }}
                src={require("../Assets/white-logo.png")}
              />
              <p className="text-lg py-3">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod
              </p>
              <div className="py-2">
                <div className="d-flex">
                  <div className="p-2">
                    <Image src={require("../Assets/twitter.png")} />
                  </div>
                  <div className="p-2">
                    <Image src={require("../Assets/insta.png")} />
                  </div>
                  <div className="p-2">
                    <Image src={require("../Assets/facebook.png")} />
                  </div>
                  <div className="p-2">
                    <Image src={require("../Assets/linkedin.png")} />
                  </div>
                </div>
              </div>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                Exporters Directory
              </h6>
              <p>
                <Link to="/" className="text-reset">
                  About Us
                </Link>
              </p>
              <p>
                <Link to="/FindProfessions" className="text-reset">
                  Privacy Policy
                </Link>
              </p>
              <p>
                <Link to="/Opportunities" className="text-reset">
                  Terms of Use
                </Link>
              </p>
              <p>
                <Link to="/CompanyProfile" className="text-reset">
                  Contact Us
                </Link>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className="mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Find</h6>
              <p>
                <Link to="/AboutMe" className="text-reset">
                  Products
                </Link>
              </p>
              <p>
                <Link to="/HowWork" className="text-reset">
                  Exporters
                </Link>
              </p>
              <p>
                <Link to="/Opportunities" className="text-reset">
                  Importers
                </Link>
              </p>
              <p>
                <Link to="/WhyMeWorks" className="text-reset">
                  Categories
                </Link>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className="mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Buy</h6>
              <p>
                <Link to="/HelpSupport" className="text-reset">
                  Post Buy Requirements
                </Link>
              </p>
              <p>
                <Link to="/FreeContact" className="text-reset">
                  View Latest
                </Link>
              </p>
              <p>
                <Link to="/Opportunities" className="text-reset">
                  Buying Requirements
                </Link>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className="mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Sell</h6>
              <p>
                <Link className="text-reset">Post Sell Offers </Link>
              </p>
              <p>
                <Link className="text-reset">View Latest </Link>
              </p>
              <p>
                <Link className="text-reset">Sell Offers</Link>
              </p>
            </MDBCol>
          </MDBRow>

          <section class="">
            <form action="">
              <div class="row d-flex justify-content-end">
                {/* <div class="col-auto">
                  <p class="pt-2">
                    <strong>Sign up for our newsletter</strong>
                  </p>
                </div> */}

                <div class="col-md-5 col-12">
                  <div class="form-outline form-white mb-4">
                    <input
                      placeholder="News Letter Subscription"
                      type="email"
                      id="form5Example21"
                      class="form-control p-3"
                    />
                  </div>
                </div>

                <div class="col-auto">
                  <button
                    type="submit"
                    class="btn btn-outline-light mb-4 p-3 bg-green-500"
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </form>
          </section>
        </MDBContainer>
      </section>

      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        E.D. © <a className="text-reset fw-bold"> Copyright</a>
      </div>
    </MDBFooter>
  );
};
