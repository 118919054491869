import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export const ConnectTextile = () => {
  return (
    <Container fluid className="bg">
      <Container>
        <div className="py-4 align-items-center webkit flex ddmm">
          <div>
            <h2 className="text-2xl text-white">
              Connect With Textile B2B Network
            </h2>
          </div>
          <div>
            <Link to="/Signup">
              <Button className="btnMain">Sign up for free</Button>
            </Link>
          </div>
        </div>
      </Container>
    </Container>
  );
};
