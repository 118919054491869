import React from "react";
import { Row, Col, Image } from "react-bootstrap";

export const OnlineShops = () => {
  return (
    <div className="mt-3 bg-white rounded-lg py-2">
      <div className="p-3">
        <h2 className="text-2xl" style={{ color: "#414042" }}>
          Online Shops
        </h2>
      </div>
      <hr />
      <div className="p-3">
        <ul>
          <li className="lililill">
            <Row className="align-items-center">
              <Col lg="3" style={{ color: "#6D6E71" }}>
                <Image
                  style={{ width: "50%", objectFit: "cover" }}
                  src={require("../Assets/logo.png")}
                />
              </Col>
              <Col lg="3" className="text-left">
                Retail / Wholesale
              </Col>
              <Col lg="3" style={{ color: "#6D6E71" }}>
                Tittle of Shop
              </Col>
              <Col lg="3" className="text-left">
                https://sfproduct.com/
              </Col>
            </Row>
          </li>
          <li className="lililill">
            <Row className="align-items-center">
              <Col lg="3" style={{ color: "#6D6E71" }}>
                <Image
                  style={{ width: "50%", objectFit: "cover" }}
                  src={require("../Assets/logo.png")}
                />
              </Col>
              <Col lg="3" className="text-left">
                Retail / Wholesale
              </Col>
              <Col lg="3" style={{ color: "#6D6E71" }}>
                Tittle of Shop
              </Col>
              <Col lg="3" className="text-left">
                https://sfproduct.com/
              </Col>
            </Row>
          </li>
          <li className="lililill">
            <Row className="align-items-center">
              <Col lg="3" style={{ color: "#6D6E71" }}>
                <Image
                  style={{ width: "50%", objectFit: "cover" }}
                  src={require("../Assets/logo.png")}
                />
              </Col>
              <Col lg="3" className="text-left">
                Retail / Wholesale
              </Col>
              <Col lg="3" style={{ color: "#6D6E71" }}>
                Tittle of Shop
              </Col>
              <Col lg="3" className="text-left">
                https://sfproduct.com/
              </Col>
            </Row>
          </li>
          <li className="lililill">
            <Row className="align-items-center">
              <Col lg="3" style={{ color: "#6D6E71" }}>
                <Image
                  style={{ width: "50%", objectFit: "cover" }}
                  src={require("../Assets/logo.png")}
                />
              </Col>
              <Col lg="3" className="text-left">
                Retail / Wholesale
              </Col>
              <Col lg="3" style={{ color: "#6D6E71" }}>
                Tittle of Shop
              </Col>
              <Col lg="3" className="text-left">
                https://sfproduct.com/
              </Col>
            </Row>
          </li>
          <li className="lililill">
            <Row className="align-items-center">
              <Col lg="3" style={{ color: "#6D6E71" }}>
                <Image
                  style={{ width: "50%", objectFit: "cover" }}
                  src={require("../Assets/logo.png")}
                />
              </Col>
              <Col lg="3" className="text-left">
                Retail / Wholesale
              </Col>
              <Col lg="3" style={{ color: "#6D6E71" }}>
                Tittle of Shop
              </Col>
              <Col lg="3" className="text-left">
                https://sfproduct.com/
              </Col>
            </Row>
          </li>
        </ul>
      </div>
    </div>
  );
};
