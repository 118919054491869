import React from "react";
import { Col, Container, Row, Image, Button } from "react-bootstrap";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { BsStarFill, BsStarHalf } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Col2MainCat } from "./Col2MainCat";
export const AllCategories = () => {
  return (
    <Container>
      <div className="py-3 webkit">
        <h2 className="text-2xl font-bold" style={{ color: "#1E75BB" }}>
          Exporters
        </h2>
      </div>
      <Row>
        <Col lg="9">
          <Row className="align-items-center py-3">
            <Col lg="3" className="webkit">
              <div className="box-cate mt-3" style={{ background: "#3BB44A" }}>
                <div>
                  <Image
                    style={{ width: "60%" }}
                    src={require("../Assets/agriculture.png")}
                  />
                </div>
                <h2 className="text-lg text-white pt-2">Agro & Agriculture</h2>
              </div>
            </Col>
            <Col lg="9">
              <div className="mt-3 bg-white rounded-lg py-3">
                <Container>
                  <Row>
                    <Col lg="4">
                      <div className="p-2">
                        <ul className=" ddes text-sm">
                          <Link to="/Category">
                            <li>Agribusiness</li>
                          </Link>
                          <li>Agricultural & Gardening Tools</li>
                          <li>Agriculture Product Stocks</li>
                          <li>Agriculture Products Processing</li>
                          <li>Agro Chemicals</li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="p-2">
                        <ul className=" ddes text-sm">
                          <li>Animal Extract</li>
                          <li>Animal Fodders</li>
                          <li>Animal Husbandry</li>
                          <li>Aquaculture Equipment & Supplies</li>
                          <li>Beans & Grains</li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="p-2">
                        <ul className=" ddes text-sm">
                          <li>Cereal</li>
                          <li>Charcoal</li>
                          <li>Cigarette & Tobacco</li>
                          <li>Cotton</li>
                          <li className="text-black">+ More Categories</li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center py-3">
            <Col lg="3" className="webkit">
              <div className="box-cate mt-3" style={{ background: "#1E75BB" }}>
                <div>
                  <Image
                    style={{ width: "60%" }}
                    src={require("../Assets/Fation.png")}
                  />
                </div>
                <h2 className="text-lg text-white pt-2">Apparel & Fashion</h2>
              </div>
            </Col>
            <Col lg="9">
              <div className="mt-3 bg-white rounded-lg py-3">
                <Container>
                  <Row>
                    <Col lg="4">
                      <div className="p-2">
                        <ul className=" ddes text-sm">
                          <li>Agribusiness</li>
                          <li>Agricultural & Gardening Tools</li>
                          <li>Agriculture Product Stocks</li>
                          <li>Agriculture Products Processing</li>
                          <li>Agro Chemicals</li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="p-2">
                        <ul className=" ddes text-sm">
                          <li>Animal Extract</li>
                          <li>Animal Fodders</li>
                          <li>Animal Husbandry</li>
                          <li>Aquaculture Equipment & Supplies</li>
                          <li>Beans & Grains</li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="p-2">
                        <ul className=" ddes text-sm">
                          <li>Cereal</li>
                          <li>Charcoal</li>
                          <li>Cigarette & Tobacco</li>
                          <li>Cotton</li>
                          <li className="text-black">+ More Categories</li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
          <Container style={{ background: "#1E75BB", borderRadius: "10px" }}>
            <div className="py-4 align-items-center webkit flex ddmm">
              <div>
                <h2 className="text-2xl text-white">
                  Connect With Textile B2B Network
                </h2>
              </div>
              <div>
                <button class="btn btn-outline-light p-2 bg-green-500">
                  Sign up for free
                </button>
              </div>
            </div>
          </Container>
        </Col>
        <Col2MainCat />
        {/* <Col lg="12">
          <Row xs={2} md={4} lg={8} className="align-items-center webkit py-3">
            <Col>
              <div className="box-cate" style={{ background: "#1E75BB" }}>
                <div>
                  <Image
                    style={{ width: "50%" }}
                    src={require("../Assets/Fation.png")}
                  />
                </div>
                <h2 className="text-lg text-white pt-2">Apparel & Fashion</h2>
              </div>
            </Col>
            <Col>
              <div className="p-2 mmd-box">
                <div className=" bg-white rounded-sm text-left px-2 py-2">
                  <h3 className=" font-bold">Frozen Green Peas & Carrots</h3>
                  <h4 style={{ color: "#6D6E71" }}>500 no. (min MOQ)</h4>
                  <Image src={require("../Assets/mutton.png")} />
                  <div className="py-1 align-items-center d-flex">
                    <div className="inline mt-0">
                      <Col lg="9">
                        <div className="flex gap-1">
                          <Image
                            style={{ objectFit: "contain" }}
                            src={require("../Assets/pakflag.png")}
                          />
                          <h2
                            style={{
                              color: "black",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            AaFood
                          </h2>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="float-right">
                          <h2 className="text-sm font-bold">$1</h2>
                        </div>
                      </Col>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col>
              <div className="p-2 mmd-box">
                <div className=" bg-white rounded-sm text-left px-2 py-2">
                  <h3 className=" font-bold">Frozen Green Peas & Carrots</h3>
                  <h4 style={{ color: "#6D6E71" }}>500 no. (min MOQ)</h4>
                  <Image src={require("../Assets/mutton.png")} />
                  <div className="py-1 align-items-center d-flex">
                    <div className="inline mt-0">
                      <Col lg="9">
                        <div className="flex gap-1">
                          <Image
                            style={{ objectFit: "contain" }}
                            src={require("../Assets/pakflag.png")}
                          />
                          <h2
                            style={{
                              color: "black",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            AaFood
                          </h2>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="float-right">
                          <h2 className="text-sm font-bold">$1</h2>
                        </div>
                      </Col>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="p-2 mmd-box">
                <div className=" bg-white rounded-sm text-left px-2 py-2">
                  <h3 className=" font-bold">Frozen Green Peas & Carrots</h3>
                  <h4 style={{ color: "#6D6E71" }}>500 no. (min MOQ)</h4>
                  <Image src={require("../Assets/mutton.png")} />
                  <div className="py-1 align-items-center d-flex">
                    <div className="inline mt-0">
                      <Col lg="9">
                        <div className="flex gap-1">
                          <Image
                            style={{ objectFit: "contain" }}
                            src={require("../Assets/pakflag.png")}
                          />
                          <h2
                            style={{
                              color: "black",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            AaFood
                          </h2>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="float-right">
                          <h2 className="text-sm font-bold">$1</h2>
                        </div>
                      </Col>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col> */}
      </Row>
    </Container>
  );
};
