import React from "react";
import { Button, Col, Container, Row, Image } from "react-bootstrap";
import { BsEnvelopeFill } from "react-icons/bs";

export const LatestSec = () => {
  return (
    <Container>
      <Row className="py-3 webkit">
        <div className="pt-5">
          <h2 className="text-2xl font-semibold" style={{ color: "#1E75BB" }}>
            Latest Buy & Sell Offers
          </h2>
          <p className="text-lg" style={{ color: "#414042" }}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
        </div>
      </Row>
      <Row className="py-5">
        <Col lg="6">
          <div className="box-latest p-3">
            <h2 className="text-xl" style={{ fontWeight: "500" }}>
              Latest Buy Offers
              <span
                className="float-right"
                style={{ color: "#939598", fontSize: "14px" }}
              >
                View all
              </span>
            </h2>
            <hr style={{ borderBottom: "1px solid #939598" }} />
            <ul className="dashed">
              <li>
                <Row className="py-2 align-items-end">
                  <Col lg="9">
                    <div className="flex gap-1 py-2">
                      <Image
                        style={{ width: "5%", objectFit: "contain" }}
                        src={require("../Assets/flag1.png")}
                      />
                      <h2 style={{ color: "#58595B", fontSize: "18px" }}>
                        Bed Sheet Peice
                      </h2>
                    </div>
                    <h2 style={{ fontSize: "14px" }}>
                      <span style={{ color: "#939598" }}>Buy Requirement </span>
                      : 1000 Boxes
                    </h2>
                  </Col>
                  <Col lg="3">
                    <Button
                      type="submit"
                      className="btn d-flex align-items-center gap-2 btn-outline-light p-2 bg-green-500 rounded-none"
                    >
                      <BsEnvelopeFill />
                      Inquiry
                    </Button>
                  </Col>
                </Row>
              </li>
              <li>
                <Row className="py-2 align-items-end">
                  <Col lg="9">
                    <div className="flex gap-1 py-2">
                      <Image
                        style={{ width: "5%", objectFit: "contain" }}
                        src={require("../Assets/flag2.png")}
                      />
                      <h2 style={{ color: "#58595B", fontSize: "18px" }}>
                        Printed Cotton Nighty Fabric
                      </h2>
                    </div>
                    <h2 style={{ fontSize: "14px" }}>
                      <span style={{ color: "#939598" }}>Buy Requirement </span>
                      : 100 Pieces
                    </h2>
                  </Col>
                  <Col lg="3">
                    <Button
                      type="submit"
                      className="btn d-flex align-items-center gap-2 btn-outline-light p-2 bg-green-500 rounded-none"
                    >
                      <BsEnvelopeFill />
                      Inquiry
                    </Button>
                  </Col>
                </Row>
              </li>
              <li>
                <Row className="py-2 align-items-end">
                  <Col lg="9">
                    <div className="flex gap-1 py-2">
                      <Image
                        style={{ width: "5%", objectFit: "contain" }}
                        src={require("../Assets/flag3.png")}
                      />
                      <h2 style={{ color: "#58595B", fontSize: "18px" }}>
                        Cotton Yarn
                      </h2>
                    </div>
                    <h2 style={{ fontSize: "14px" }}>
                      <span style={{ color: "#939598" }}>Buy Requirement </span>
                      : 20 Tons
                    </h2>
                  </Col>
                  <Col lg="3">
                    <Button
                      type="submit"
                      className="btn d-flex align-items-center gap-2 btn-outline-light p-2 bg-green-500 rounded-none"
                    >
                      <BsEnvelopeFill />
                      Inquiry
                    </Button>
                  </Col>
                </Row>
              </li>
              <li>
                <Row className="py-2 align-items-end">
                  <Col lg="9">
                    <div className="flex gap-1 py-2">
                      <Image
                        style={{ width: "5%", objectFit: "contain" }}
                        src={require("../Assets/flag4.png")}
                      />
                      <h2 style={{ color: "#58595B", fontSize: "18px" }}>
                        Hackled Flax
                      </h2>
                    </div>
                    <h2 style={{ fontSize: "14px" }}>
                      <span style={{ color: "#939598" }}>Buy Requirement </span>
                      : 2 MT
                    </h2>
                  </Col>
                  <Col lg="3">
                    <Button
                      type="submit"
                      className="btn d-flex align-items-center gap-2 btn-outline-light p-2 bg-green-500 rounded-none"
                    >
                      <BsEnvelopeFill />
                      Inquiry
                    </Button>
                  </Col>
                </Row>
              </li>
              <li>
                <Row className="py-2 align-items-end">
                  <Col lg="9">
                    <div className="flex gap-1 py-2">
                      <Image
                        style={{ width: "5%", objectFit: "contain" }}
                        src={require("../Assets/flag5.png")}
                      />
                      <h2 style={{ color: "#58595B", fontSize: "18px" }}>
                        Fibre & Raw Textiles
                      </h2>
                    </div>
                    <h2 style={{ fontSize: "14px" }}>
                      <span style={{ color: "#939598" }}>Buy Requirement </span>
                      : 20 container
                    </h2>
                  </Col>
                  <Col lg="3">
                    <Button
                      type="submit"
                      className="btn d-flex align-items-center gap-2 btn-outline-light p-2 bg-green-500 rounded-none"
                    >
                      <BsEnvelopeFill />
                      Inquiry
                    </Button>
                  </Col>
                </Row>
              </li>
            </ul>
          </div>
          <div className="webkit py-4">
            <button
              className="rounded-md text-white py-3 px-3 w-6/12"
              style={{ background: "#1E75BB" }}
            >
              Post Buy Offer
            </button>
          </div>
        </Col>
        <Col lg="6">
          <div className="box-latest p-3">
            <h2 className="text-xl" style={{ fontWeight: "500" }}>
              Latest Sell Offers
              <span
                className="float-right"
                style={{ color: "#939598", fontSize: "14px" }}
              >
                View all
              </span>
            </h2>
            <hr style={{ borderBottom: "1px solid #939598" }} />
            <ul className="dashed">
              <li>
                <Row className="py-2 align-items-end">
                  <Col lg="9">
                    <div className="flex gap-1 py-2">
                      <Image
                        style={{ width: "5%", objectFit: "contain" }}
                        src={require("../Assets/flag1.png")}
                      />
                      <h2 style={{ color: "#58595B", fontSize: "18px" }}>
                        Bed Sheet Peice
                      </h2>
                    </div>
                    <h2 style={{ fontSize: "14px" }}>
                      <span style={{ color: "#939598" }}>Buy Requirement </span>
                      : 1000 Boxes
                    </h2>
                  </Col>
                  <Col lg="3">
                    <Button
                      type="submit"
                      className="btn d-flex align-items-center gap-2 btn-outline-light p-2 bg-green-500 rounded-none"
                    >
                      <BsEnvelopeFill />
                      Inquiry
                    </Button>
                  </Col>
                </Row>
              </li>
              <li>
                <Row className="py-2 align-items-end">
                  <Col lg="9">
                    <div className="flex gap-1 py-2">
                      <Image
                        style={{ width: "5%", objectFit: "contain" }}
                        src={require("../Assets/flag2.png")}
                      />
                      <h2 style={{ color: "#58595B", fontSize: "18px" }}>
                        Printed Cotton Nighty Fabric
                      </h2>
                    </div>
                    <h2 style={{ fontSize: "14px" }}>
                      <span style={{ color: "#939598" }}>Buy Requirement </span>
                      : 100 Pieces
                    </h2>
                  </Col>
                  <Col lg="3">
                    <Button
                      type="submit"
                      className="btn d-flex align-items-center gap-2 btn-outline-light p-2 bg-green-500 rounded-none"
                    >
                      <BsEnvelopeFill />
                      Inquiry
                    </Button>
                  </Col>
                </Row>
              </li>
              <li>
                <Row className="py-2 align-items-end">
                  <Col lg="9">
                    <div className="flex gap-1 py-2">
                      <Image
                        style={{ width: "5%", objectFit: "contain" }}
                        src={require("../Assets/flag3.png")}
                      />
                      <h2 style={{ color: "#58595B", fontSize: "18px" }}>
                        Cotton Yarn
                      </h2>
                    </div>
                    <h2 style={{ fontSize: "14px" }}>
                      <span style={{ color: "#939598" }}>Buy Requirement </span>
                      : 20 Tons
                    </h2>
                  </Col>
                  <Col lg="3">
                    <Button
                      type="submit"
                      className="btn d-flex align-items-center gap-2 btn-outline-light p-2 bg-green-500 rounded-none"
                    >
                      <BsEnvelopeFill />
                      Inquiry
                    </Button>
                  </Col>
                </Row>
              </li>
              <li>
                <Row className="py-2 align-items-end">
                  <Col lg="9">
                    <div className="flex gap-1 py-2">
                      <Image
                        style={{ width: "5%", objectFit: "contain" }}
                        src={require("../Assets/flag4.png")}
                      />
                      <h2 style={{ color: "#58595B", fontSize: "18px" }}>
                        Hackled Flax
                      </h2>
                    </div>
                    <h2 style={{ fontSize: "14px" }}>
                      <span style={{ color: "#939598" }}>Buy Requirement </span>
                      : 2 MT
                    </h2>
                  </Col>
                  <Col lg="3">
                    <Button
                      type="submit"
                      className="btn d-flex align-items-center gap-2 btn-outline-light p-2 bg-green-500 rounded-none"
                    >
                      <BsEnvelopeFill />
                      Inquiry
                    </Button>
                  </Col>
                </Row>
              </li>
              <li>
                <Row className="py-2 align-items-end">
                  <Col lg="9">
                    <div className="flex gap-1 py-2">
                      <Image
                        style={{ width: "5%", objectFit: "contain" }}
                        src={require("../Assets/flag5.png")}
                      />
                      <h2 style={{ color: "#58595B", fontSize: "18px" }}>
                        Fibre & Raw Textiles
                      </h2>
                    </div>
                    <h2 style={{ fontSize: "14px" }}>
                      <span style={{ color: "#939598" }}>Buy Requirement </span>
                      : 20 container
                    </h2>
                  </Col>
                  <Col lg="3">
                    <Button
                      type="submit"
                      className="btn d-flex align-items-center gap-2 btn-outline-light p-2 bg-green-500 rounded-none"
                    >
                      <BsEnvelopeFill />
                      Inquiry
                    </Button>
                  </Col>
                </Row>
              </li>
            </ul>
          </div>
          <div className="webkit py-4">
            <button
              className="rounded-md text-white py-3 px-3 w-6/12"
              style={{ background: "#1E75BB" }}
            >
              Post Sell Offer
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
